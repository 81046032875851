import React, { FC } from "react";
import rightImgPng from "images/appartment.jpg";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import Button from "../../shared/Button/Button";

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  rightImg = rightImgPng,
  type = "type1",
}) => {
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${
        type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
      } ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div className="w-96 flex-grow">
        <NcImage src={rightImg}/>
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${
          type === "type1" ? "lg:pl-16" : "lg:pr-16"
        }`}
      >
        <ul className="space-y-6 mt-2">
          <li>
            <i className={"las la-handshake text-4xl text-teal-600"}></i>
            <span className="block text-xl font-semibold">
              1. RAISE
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              We raise money for each fund using SEC-registered Reg A or Reg D offerings. Each fund is an LLC, and our investors are considered Limited Partners (LP) whenever they invest.
            </span>
          </li>
          <li>
            <i className={"las la-city text-4xl text-teal-600"}></i>
            <span className="block text-xl font-semibold">
              2. PURCHASE
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              We purchase income-producing real estate with 100% cash. We perform value-added updates as necessary to increase the property's value.
            </span>
          </li>
          <li>
            <i className={"las la-money-bill-wave text-4xl text-teal-600"}></i>
            <span className="block text-xl font-semibold">
              3. SEND
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              After all expenses are paid, we send our investors quarterly distributions. Once the property has been stabilized, we analyze and execute various exit strategies to return funds to our investors.
            </span>
          </li>
        </ul>
        <Button
            className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-orange-500 hover:bg-primary-700 text-white mt-6`}
            href={"/signup"}
        >Get Started</Button>
      </div>
    </div>
  );
};

export default SectionOurFeatures;
