import React, { FC, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import { useLocation, useNavigate } from "react-router-dom";
import {PHOTOS } from "./constant";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "../Layout";

const StayDetailPageContainer: FC<{}> = () => {

  const thisPathname = useLocation().pathname;
  const router = useNavigate();

  const handleOpenModalImageGallery = () => {
    router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  const renderTitleSection = () => {
    return (
      <div className="listingSection__wrap !space-y-6 rounded">
        {/* 1 */}
        <div className="flex items-center gap-2">
          <Badge name="Accredited" />
          <Badge name="Non-Accredited" />
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
            RentStream Multifamily Fund 1
        </h2>
        <div className="flex items-center">
          <span className="text-neutral-500 dark:text-neutral-400">
            Offered by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              RentStream Capital LLC
            </span>
          </span>
        </div>
        <div className="flex items-center">
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1">Houston, TX</span>
          </span>
        </div>
      </div>
    );
  };

  const renderOverview = () => {
    return (
      <div className="listingSection__wrap rounded">
        <h2 className="text-2xl font-semibold">Overview</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>
            RentStream is pleased to present the Las Palmas Apartments (the "property") offering located in Houston, Texas. The property consists of below-market rents thereby allowing new ownership to pursue value-add through a renovation strategy and releasing at market rates. The property spans across 10 buildings comprised of a wood-frame construction with masonry, stucco, painted siding exterior walls and composition shingles pitched roofs with a unit mix consisting of 48 1BD/1BR, 32 2BD/1BR, and 48 2BD/2BR. Houston is ranked #3 among U.S. metropolitan areas in Fortune 500 headquarters with Phillips 66 ($114.9 billion in annual revenue), Sysco ($51.3 billion in annual revenue), Hewlett Packard Enterprise ($27.8 billion in annual revenue), Waste Management ($17.9 billion in annual revenue), and more. Furthermore, Houston serves as homage to a multitude of academic facilities including the University of Houston (±47,000 students), Texas Tech University (±33,132 students), Rice University (±8,212 students), and Texas Southern University (±7,524 students). The city of Houston features a dense population count of ±2,310,000 people, an employed population of ±1,130,000 people, and an average household income of ±$70,892 within a 10-mile radius of the property. The largest industries in Houston are Health Care & Social Assistance (±125,103 people), Construction (±123,333 people), and Retail Trade (±111,697 people) while the highest paying industries are Oil & Gas Extraction (±$105,584), Agriculture (±$102,752), and Management of Companies & Enterprises (±$102,727) based on information provided by the United States Census Bureau.
For additional details on Fortune 500 companies with a placement in Houston, please visit the following link: https://www.houston.org/houston-data/fortune-500-companies.
          </span>
          <br />
        </div>
      </div>
    );
  };

  const renderBusinessPlan = () => {
    return (
      <div className="listingSection__wrap rounded">
        <h2 className="text-2xl font-semibold">Business Plan</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>
            Located in the East Aldine district in an unincorporated area of northeast Houston, TX, in Harris County. The area is made up of
largely Hispanic households. Many small industrial facilities are located in and near the community. Its location is inside Beltway 8, just
east of Hwy 59 and west of Homestead Rd. The district government estimates that the district is 15 minutes driving distance from
Downtown Houston, and is in proximity to the Houston Ship Channel, the Port of Houston, George Bush IAH, and Humble. The area is
largely made up of homes dating in the mid to late 70's. Approximately 70% of the population in the East Aldine area are renters. The
rental occupancy in the area is above average when compared to the city of Houston.
          </span>
          <br />
          <br />
          <span>
            The property is highly upgradeable. RentStream will pursue interior improvements to allow for an increase in rents. These are
granite counters, new lighting and hardware package, cabinets, and faux wood flooring. Some possible exterior improvements would be adding covered parking, landscaping improvements to improve the curb appeal, full paint, wood replacement, and playground and picnic areas on the property.
          </span>
          <br /> <br />
          <span>
            RentStream believes that with interior and exterior upgrades and the correction of deferred maintenance, the property could see an additional upside in the rents (the subject's rents are below properties in the area that have been upgraded).
          </span>
        </div>
      </div>
    );
  };

  const renderDocuments = () => {
    return (
      <div className="listingSection__wrap rounded">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Documents</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 mb-3 space-y-5">
            <div className="flex justify-between items-center space-x-4 rounded-lg">
                <a className="block font-medium text-teal-600" target="_blank" rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/rentstream-web.appspot.com/o/DC%20Las%20Palmas%20II%20-%20OM%20Seller%20Provided.pdf?alt=media&token=823dcdba-9800-4258-a1b7-dd226816c251">Offering Memorandum</a>
              {/*<div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>*/}
                <span>PDF</span>
            </div>
            <div className="flex justify-between items-center space-x-4 rounded-lg">
                <a className="block font-medium text-teal-600" target="_blank" rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/rentstream-web.appspot.com/o/DC%20RR%20128%20units%2002.07.23.pdf?alt=media&token=ce7b0e36-456c-4b0c-9a4d-5ed63b45f1d9">Rent Roll</a>
              {/*<div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>*/}
                <span>PDF</span>
            </div>
            <div className="flex justify-between items-center space-x-4 rounded-lg">
                <a className="block font-medium text-teal-600" target="_blank" rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/rentstream-web.appspot.com/o/DC%20T12%20Anually%20Statements%2022%20Filiz%20Casa%20llc%202022%20Broker.xlsx?alt=media&token=5a6b98c9-1b21-47bf-bbb0-3b52e8031507">Annual Statements</a>
              {/*<div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>*/}
              <span>XLSX</span>
            </div>
            <div className="flex justify-between items-center space-x-4 rounded-lg">
                <a className="block font-medium text-teal-600" target="_blank" rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/rentstream-web.appspot.com/o/DC%20Las%20Palmas%20II%20Yardi%20Report%20%20-%20E-Brochure.pdf?alt=media&token=9e690c9e-86c6-4370-b5d2-c105e0465858">Yardi Report</a>
              {/*<div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>*/}
              <span>PDF</span>
            </div>

          </div>
        </div>
      </div>
    );
  };

  const renderManagement = () => {
    return (
      <div className="listingSection__wrap rounded">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Management</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <span className="block text-xl font-medium">
              RentStream Capital LLC
            </span>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          RentStream Capital (“RentStream” or “Sponsor”) believes that commercial real estate is a critical component of any diversified investment portfolio. Not only does it provide stable and predictable cash flows, but it also offers the potential for long-term appreciation and significant tax benefits. However, historically, commercial real estate has been accessible primarily to wealthy individuals and institutional investors. At RentStream, our mission is to democratize access to commercial real estate investing. With access to this asset class, investors of all income levels can take advantage of commercial real estate benefits, not just the wealthy.


        </span>
      </div>
    );
  };

  const renderDisclaimer = () => {
    return (
      <div className="listingSection__wrap rounded">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Disclaimer</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold pt-8">Risk of Investment</h4>
          <span className="block mt-3 text-neutral-6000 dark:text-neutral-400">
              Investing involves risk, including loss of principal. Past performance does not guarantee or indicate future results. Any historical returns, expected returns, or probability projections may not reflect actual future performance. While the data we use from third parties is believed to be reliable, we cannot ensure the accuracy or completeness of data provided by investors or other third parties. Neither RentStream Capital LLC nor any of its affiliates provide tax advice and do not represent in any manner that the outcomes described herein will result in any particular tax consequence. Offers to sell, or solicitations of offers to buy, any security can only be made through official offering documents that contain important information about investment objectives, risks, fees and expenses. Prospective investors should consult with a tax or legal adviser before making any investment decision.
          </span>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl rounded space-y-4 bg-neutral-50 pt-5">
        <div className="flex items-center justify-center justify-between">
          <span className="text-neutral-500 dark:text-neutral-400">
            Property Type
          </span>
          <span className="font-semibold">
            Multifamily
          </span>
        </div>
        <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="flex items-center justify-center justify-between">
          <span className="text-neutral-500 dark:text-neutral-400">
            Investment Profile
          </span>
          <span className="font-semibold">
            Value Add
          </span>
        </div>
        <div className="w-full border-b border-neutral-200 dark:border-neutral-700 "></div>
        <div className="flex items-center justify-center justify-between">
          <span className="text-neutral-500 dark:text-neutral-400">
            Targeted Investment Period
          </span>
          <span className="font-semibold">
            5 Years
          </span>
        </div>
        <div className="w-full border-b border-neutral-200 dark:border-neutral-700 "></div>
        <div className="flex items-center justify-center justify-between">
          <span className="text-neutral-500 dark:text-neutral-400">
            Minimum Investment
          </span>
          <span className="font-semibold">
            $15,000
          </span>
        </div>
        <div className="w-full border-b border-neutral-200 dark:border-neutral-700 "></div>
        <div className="flex items-center justify-center justify-between">
          <span className="text-neutral-500 dark:text-neutral-400">
            Targeted IRR
          </span>
          <span className="font-semibold">
            24.50%
          </span>
        </div>
        <div className="w-full border-b border-neutral-200 dark:border-neutral-700 "></div>

        {/* SUBMIT */}
        <ButtonPrimary href={"mailto:info@rentstreamcapital.com"}>Contact RentStream</ButtonPrimary>
      </div>
    );
  };

  return (
    <div className="nc-ListingDetailPage">
      {/*  HEADER */}
      <header className="rounded pt-10">
        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
          <div
            className="col-span-2 row-span-3 sm:row-span-2 relative rounded overflow-hidden cursor-pointer "
            onClick={handleOpenModalImageGallery}
          >
            <img
              className="absolute inset-0 object-cover rounded w-full h-full"
              src={PHOTOS[0]}
              alt=""
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
          {PHOTOS.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
            <div
              key={index}
              className={`relative rounded overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                }`}
            >
              <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                <img
                  className="absolute inset-0 object-cover rounded w-full h-full"
                  src={item || ""}
                  alt=""
                  sizes="400px"
                />
              </div>

              {/* OVERLAY */}
              <div
                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                onClick={handleOpenModalImageGallery}
              />
            </div>
          ))}
        </div>
      </header>

      {/* MAIN */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderTitleSection()}
          {renderOverview()}
          {renderManagement()}
          {renderBusinessPlan()}
          {renderDocuments()}
          {renderDisclaimer()}
        </div>

        {/* SIDEBAR */}
        <div className="lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </main>
    </div>
  );
};

export default function ListingDetailPage() {

  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  );
}
