import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import React, { FC, useEffect } from "react";
import SectionGridListings from "./SectionGridListings";
import { Helmet } from "react-helmet";
import imagePng from "../../images/pexels-adrien-olichon-3709398.jpg";
// @ts-ignore
import coverVideo from "../../images/cover-video.mp4";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import FAQCard from "../../components/FAQ/FAQCard";
import Button from "../../shared/Button/Button";
import SectionHowItWork from "../../components/SectionHowItWork/SectionHowItWork";
import Heading from "../../components/Heading/Heading";
import SectionOurFeatures from "../../components/SectionOurFeatures/SectionOurFeatures";
import SectionHero from "../../components/SectionHero/SectionHero";
import BgGlassmorphism from "../../components/BgGlassmorphism/BgGlassmorphism";

export interface LandingPageProps {
  className?: string;
}

const LandingPage: FC<LandingPageProps> = ({
  className = "",
}) => {


  return (
    <div
      className={`nc-ListingRealEstatePage relative overflow-hidden ${className}`}
      data-nc-id="LandingPage"
    >
      <Helmet>
        <title>RentStream | Commercial real estate investing for everyone</title>
      </Helmet>
      {/*<div style={{paddingTop: "10rem"}}>*/}
      {/*    <video autoPlay loop muted playsInline className="absolute top-0 left-0 w-full object-cover" style={{height: "40rem"}}>*/}
      {/*      <source src={coverVideo} type="video/mp4"/>*/}
      {/*    </video>*/}
      {/*</div>*/}
      <BgGlassmorphism />
      <div className="relative">
        {/*<div className="container" style={{backgroundColor: 'rgb(6, 45, 96)', height: '40rem'}}>*/}
        {/*  <h1 className="font-semibold text-4xl md:text-4xl xl:text-6xl lg:block w-full pb-10 text-white ">*/}
        {/*    The safest way to invest in<br/>commercial real estate*/}
        {/*  </h1>*/}
        {/*  <p className="lg:text-2xl text-white mb-8">We believe that everyone should have access to commercial real estate, not just the wealthy.</p>*/}
        {/*  <Button*/}
        {/*      className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-white mb-12`}*/}
        {/*     href={"/signup"}*/}
        {/*  >Get Started Now</Button>*/}
        {/*</div>*/}
        <div className="container pt-10 lg:pt-16">
          <SectionHero/>
        </div>
        <div className="relative py-16 text-center bg-orange-50 mt-20">
          <div className={"container"}>
            <Heading isCenter>Invest with RentStream</Heading>
            <p className="text-neutral-500 text-xl">Unlike other Sponsors, we carry no debit with zero loan default risk. We purchase existing cash-flowing properties, allowing you to earn an income from day one without the maintenance or operational responsibilities of owning a building.</p>
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-x-6 mt-6 mb-12">
              <div className="pt-10 flex-col items-center">
                <span className={"flex-col flex"}>
                  <i className="las la-coins text-6xl text-teal-600"></i>
                  <span className="ml-2 text-xl font-semibold ">CASH FLOW</span>
                </span>
                <p className="text-neutral-500 mt-2 mx-10">After expenses are paid, quarterly distributions go out to investors.</p>
              </div>
              <div className="pt-10 flex-col items-center">
                <span className={"flex-col flex"}>
                  <i className="lab la-creative-commons-zero text-6xl text-teal-600"></i>
                  <span className="ml-2 text-xl font-semibold ">ZERO LEVERAGE</span>
                </span>
                <p className="text-neutral-500 mt-2 mx-10">We don't answer to banks, and increasing interest rates don't impact your returns.</p>
              </div>
              <div className="pt-10 flex-col items-center">
                <span className={"flex-col flex"}>
                  <i className="las la-chart-bar text-6xl text-teal-600"></i>
                  <span className="ml-2 text-xl font-semibold ">HIGHER RETURNS</span>
                </span>
                <p className="text-neutral-500 mt-2 mx-10">Carrying $0 in debt equals higher ROI for you. Simply put, your funds go towards acquiring and maintaining properties, not servicing a debt.</p>
              </div>
              <div className="pt-10 flex-col items-center">
                <span className={"flex-col flex"}>
                  <i className="las la-percentage text-6xl text-teal-600"></i>
                  <span className="ml-2 text-xl font-semibold ">TAX BENEFITS</span>
                </span>
                <p className="text-neutral-500 mt-2 mx-10">Use depreciation as a tax write-off to keep more of your profits.</p>
              </div>
              <div className="pt-10 flex-col items-center">
                <span className={"flex-col flex"}>
                  <i className="las la-mountain text-6xl text-teal-600"></i>
                  <span className="ml-2 text-xl font-semibold ">STABILITY</span>
                </span>
                <p className="text-neutral-500 mt-2 mx-10">Multifamily is less volatile and continues to outperform traditional stock-based investments.</p>
              </div>
              <div className="pt-10 flex-col items-center">
                <span className={"flex-col flex"}>
                   <i className="las la-chart-area text-6xl text-teal-600"></i>
                  <span className="ml-2 text-xl font-semibold ">APPRECIATION</span>
                </span>
                <p className="text-neutral-500 mt-2 mx-10">Forced appreciation through strategic value plays to increase the property's overall value.</p>
              </div>
            </div>
            <a className="bg-orange-500 text-white px-4 py-3 sm:px-6 text-sm sm:text-base font-medium rounded" href={"#howitworks"}>Learn More</a>
          </div>
        </div>
        <div className="py-16 dark:bg-white dark:bg-opacity-5 text-center">
          <div className="container">
            <Heading isCenter desc={"Compare and review each fund to find the right investment opportunity for your portfolio."}>Active Funds</Heading>
            <SectionGridListings className="pt-10" />
          </div>
        </div>
        <div id="howitworks" className={"py-16 bg-gray-100"}>
          <div className={"container"}>
            <Heading isCenter desc={"We follow a fully transparent three-step process. Additionally, our process is audited to give you a piece of mind."} className={"mb-8 lg:mb-3"}>How It Works</Heading>
            <SectionOurFeatures />
          </div>
        </div>
        <div className={"dark:bg-neutral-100 py-16 container"}>
          <FAQCard />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
