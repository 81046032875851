import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import Badge from "shared/Badge/Badge";
import { StayDataType } from "data/types";
import {useSelector} from "react-redux";

export interface ListingCardProps {
  className?: string;
  data?: StayDataType;
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const ListingCard: FC<ListingCardProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  const {
    galleryImgs,
    title,
      description,
      investmentState,
    href,
    like,
    id,
  } = data;

  const signedIn = useSelector((state: any) => state.auth.signedIn);

  const renderSliderGallery = () => {
    return (
      <div className="flex-shrink-0 w-full">
        <GallerySlider
          galleryImgs={galleryImgs}
          className="w-full h-full overflow-hidden will-change-transform"
          uniqueID={`ListingCard_${id}`}
          href={href}
        />
          {investmentState === 'JOIN WAITLIST' ?
              <div className={`flex items-center justify-center text-xs font-semibold py-0.5 px-3 text-black absolute left-5 top-5 bg-white`}>
              {investmentState}
              </div> :
              <div className={`flex items-center justify-center text-xs font-semibold py-0.5 px-3 text-white absolute left-5 top-5 bg-teal-600`}>
                  {investmentState}
              </div>}
      </div>
    );
  };

  const investmentDetails = () => {
    return (
        <div className="space-y-2 w-full">
        <div className="w-full border-b border-neutral-200 dark:border-neutral-800 "></div>
        <div className="flex items-center justify-center justify-between">
            <span className="text-sm text-neutral-500 dark:text-neutral-400 ">
            Location
           </span>
            <span className="text-sm font-semibold">
            Houston, TX
          </span>
        </div>
        <div className="w-full border-b border-neutral-200 dark:border-neutral-800 "></div>
          <div className="flex items-center justify-center justify-between">
            <span className="text-sm text-neutral-500 dark:text-neutral-400 ">
            Property Type
           </span>
               <span className="text-sm font-semibold">
            Multifamily
          </span>
          </div>
          <div className="w-full border-b border-neutral-200 dark:border-neutral-800"></div>
          <div className="flex items-center justify-center justify-between">
            <span className="text-sm text-neutral-500 dark:text-neutral-400 ">
            Investment Profile
           </span>
               <span className="text-sm font-semibold">
            Value Add
          </span>
          </div>
          <div className="w-full border-b border-neutral-200 dark:border-neutral-800 "></div>
          <div className="flex items-center justify-center justify-between">
            <span className="text-sm text-neutral-500 dark:text-neutral-400 ">
            Targeted Investment Period
           </span>
              <span className="text-sm font-semibold">
            5 Years
          </span>
          </div>
          <div className="w-full border-b border-neutral-200 dark:border-neutral-800 "></div>
            <div className="flex items-center justify-center justify-between">
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
            Minimum Investment
           </span>
                 <span className="text-sm font-semibold">
            $15,000
          </span>
            </div>
            <div className="w-full border-b border-neutral-200 dark:border-neutral-800 "></div>
            <div className="flex items-center justify-center justify-between">
            <span className="text-sm text-neutral-500 dark:text-neutral-400 ">
            Targeted IRR
           </span>
                 <span className="text-sm font-semibold">
            24.50%
          </span>
            </div>
            <div className="w-full border-b border-neutral-200 dark:border-neutral-800 "></div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="p-4 flex flex-col">
        <div className="space-y-4 w-full">
          <div className="flex items-center space-x-2">
            <h2 className="text-lg ">
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
          {investmentDetails()}
          <div className="flex w-full justify-center">
            <span className="flex items-center justify-center w-full py-4 rounded leading-none text-base font-semibold text-white bg-teal-600">
              <p>View Details</p>
                <i className="text-md las la-angle-right ml-2"></i>
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-ListingCard group relative bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-800 rounded overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="ListingCard"
    >
      <Link to={!signedIn ? "/login" : href} target="_blank" rel="noopener noreferrer" className="absolute inset-0"></Link>
      <div className="h-full w-full flex-row">
        {renderSliderGallery()}
        {renderContent()}
      </div>

      {/*<BtnLikeIcon*/}
      {/*  colorClass={` bg-neutral-100 dark:bg-neutral-700 hover:bg-neutral-200 hover:bg-opacity-70 text-neutral-6000 dark:text-neutral-400`}*/}
      {/*  isLiked={like}*/}
      {/*  className="absolute right-5 top-5 sm:right-3 sm:top-3 "*/}
      {/*/>*/}
    </div>
  );
};

export default ListingCard;
