import rightImg from "images/about-hero-right.jpg";
import React, { FC, useEffect } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {


  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About | RentStream</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="Our Mission"
          btnText=""
          subHeading="Commercial real estate is a critical component of any diversified investment portfolio. Not only does it provide stable and predictable cash flows, but it also offers the potential for long-term appreciation and significant tax benefits. However, historically, commercial real estate has been accessible primarily to wealthy individuals and institutional investors. At RentStream, our mission is to democratize access to commercial real estate investing. Our low-risk, high-reward acquisition strategy allows investors of all income levels to take advantage of commercial real estate benefits, not just the wealthy."
        />

        <SectionFounder />
        {/*<div className="relative py-16">*/}
        {/*  <BackgroundSection />*/}
        {/*  <SectionClientSay uniqueClassName="PageAbout_" />*/}
        {/*</div>*/}

        {/*<SectionStatistic />*/}

        {/*<SectionSubscribe2 />*/}
      </div>
    </div>
  );
};

export default PageAbout;
