import React from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_WEB } from "data/navigation";
import { useSelector } from "react-redux";

function Navigation() {
  const isLoggedIn = useSelector((state: any) => state.auth.signedIn);
  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {NAVIGATION_WEB.map((item) => {
        // check if the current menu item is "Login" or "Signup"
        if ((item.id === "login" || item.id === "signup") && isLoggedIn) {
          // if the user is logged in, skip rendering these menu items
          return null;
        } else {
          // otherwise, render the menu item
          return <NavigationItem key={item.id} menuItem={item} />;
        }
      })}
    </ul>
  );
}

export default Navigation;
