import React, { FC } from "react";
import Heading from "components/Heading/Heading";
import FlightCard, { FlightCardProps } from "components/FlightCard/FlightCard";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionGridFilterCardProps {
    className?: string;
}

const DEMO_DATA: FlightCardProps["data"][] = [
    {
        id: "1",
        question: "Who can invest in the RentStream Funds?",
        answer: "To invest in RentStream Funds, an investor can be accredited or non-accredited. However, each fund is limited to 35 non-accredited investors. There a no limits to the number of accredited investors.",
    },
    {
        id: "2",
        question: "What is an accredited investor?",
        answer: "“Accredited investor” has been defined by the Securities and Exchange Commission (SEC) as a test to determine who is eligible to participate in certain private capital market offerings. Generally an individual person is an accredited investor if they: (1) had an income of $200,000 over the last two years (or $300,000 jointly with a spouse or spousal equivalent) and reasonably expect to satisfy the same criteria in the current year, (2) have a net worth of over $1,000,000, either alone or together with a spouse or spousal equivalent (excluding the person’s primary residence) or (3) hold a Series 7, Series 82, or Series 65 financial services license and is in good standing. An individual must be an accredited investor to invest with RentStream.",
    },
    {
        id: "3",
        question: "What is a Reg A offering?",
        answer: "Regulation A offering is a type of securities offering that allows private companies to raise up to $75 million from the general public without going through a full initial public offering (IPO) process. This type of offering is regulated by the Securities and Exchange Commission (SEC) and is intended to provide smaller companies with an alternative way to raise capital and gain access to public markets. Under a Reg A offering, a company can offer securities to both accredited and non-accredited investors. However, the offering is subject to certain limitations and disclosure requirements, including the submission of an offering circular to the SEC for review and approval. The offering circular must include detailed information about the company, its business, the securities being offered, and the risks associated with investing in the company.",
    },
    {
        id: "4",
        question: "What is a Reg D offering?",
        answer: "Regulation D offering is a type of securities offering that allows companies to raise capital from accredited investors without having to go through the rigorous and costly registration process required for public offerings. It is regulated by the Securities and Exchange Commission (SEC) under Rule 506 of Regulation D. Under a Reg D offering, companies can offer securities to accredited investors, such as high net worth individuals or institutional investors, without having to register the securities with the SEC. This allows companies to raise capital more quickly and at a lower cost than through a public offering. However, the securities must be sold only to accredited investors, and the offering must comply with specific disclosure requirements. There are several types of Reg D offerings, including Rule 506(b) and Rule 506(c) offerings. Rule 506(b) allows companies to sell securities to up to 35 non-accredited investors, provided they meet certain requirements, such as having a pre-existing relationship with the company. Rule 506(c) allows companies to sell securities to accredited investors only, but permits general solicitation and advertising to find potential investors.",
    },
    {
        id: "3",
        question: "I am investing through a business entity (i.e. a trust, LLC, partnership or corporation). How does an entity qualify as an “accredited investor“?",
        answer: "In addition to qualifying as an individual, there are other categories of accredited investors which will satisfy the SEC’s definitions: (1) any trust, with total assets in excess of $5 million, not formed specifically to purchase the subject securities, whose purchase is directed by a sophisticated person, or (2) certain entity with total investments in excess of $5 million, not formed to specifically purchase the subject securities, or (3) any entity in which all of the equity owners are accredited investors.",
    },
    {
        id: "4",
        question: "What is the minimum investment?",
        answer: "The minimum investment amount is typically $15,000. However, some Funds may require a higher investment in some cases.",
    },
    {
        id: "5",
        question: "Will I receive a K-1?",
        answer: "You will receive a K-1 for investing in any RentStream Fund. We aim to provide annual K-1 statements by the last week of March.",
    },
    {
        id: "6",
        question: "Why does RentStream not use leverage?",
        answer: "Most Sponsors use leverage to buy real estate properties. For example, you can buy a $10M property with only $2.5M using short-term loans, thus requiring refinancing the loan in the coming years or exiting the property before the loan matures. In today's market, investors will lose 7-10% annually to mortgage interest while hoping to generate 5-10% in annualized returns. Your risk will compound when interest rates rise and property values decrease. While this is an attractive risk vs. reward model for some, RentStream takes the more conservative approach by acquiring all properties using 100% cash. By doing this, we generate higher returns for our investors with zero loan default risk. In addition, our underwriting standards do not change based on higher or lower property values. Instead, we focus on acquiring properties with the potential of generating the most return using our low-risk acquisition strategies. Most importantly, we negotiate favorable prices when acquiring a property as cash buyers. This strategy helps increase shareholder value over time while managing the property."
    }
];

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
                                                                   className = "",
                                                               }) => {
    return (
        <div
            className={`${className}`}
        >
            <Heading isCenter>FAQs</Heading>
            <div className="grid grid-cols-1 gap-6 mt-12">
                {DEMO_DATA.map((item, index) => (
                    <FlightCard defaultOpen={!index} key={index} data={item} />
                ))}
            </div>
        </div>
    );
};

export default SectionGridFilterCard;
