import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";

interface FormData {
  name: string;
  email: string;
  message: string;
}

const info = [
  {
    title: "ADDRESS",
    desc: "1120 NASA Parkway Ste 220C, Houston, TX 77058",
  },
  {
    title: "EMAIL",
    desc: "info@rentstreamcapital.com",
  },
  // {
  //   title: "☎ PHONE",
  //   desc: "000-123-456-7890",
  // },
];

const PageContact = () => {

  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { name, email, message } = formData;
    const subject = `New message from ${name} (${email})`;
    const body = `Message: ${message}`;
    window.location.href = `mailto:info@rentstreamcapital.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    setFormData({
      name: '',
      email: '',
      message: '',
    });
  };

  return (
    <div
      className={`nc-PageContact overflow-hidden`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact | RentStream</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              {/*<div>*/}
              {/*  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">*/}
              {/*    🌏 SOCIALS*/}
              {/*  </h3>*/}
              {/*  <SocialsList className="mt-2" />*/}
              {/*</div>*/}
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
                <label className="block">
                  <Label>Full name</Label>
                  <Input
                    placeholder="Jane Doe"
                    type="text"
                    className="mt-1"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label className="block">
                  <Label>Email address</Label>
                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label className="block">
                  <Label>Message</Label>
                  <Textarea
                      id="message"
                      name="message"
                      className="mt-1"
                      rows={6}
                      value={formData.message}
                      onChange={handleChange}
                      required/>
                </label>
                <div>
                  <ButtonPrimary type="submit">Send Message</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      {/*<div className="container">*/}
      {/*  <div className="relative py-16">*/}
      {/*    <BackgroundSection />*/}
      {/*    <SectionClientSay uniqueClassName="Pagecontact_" />*/}
      {/*  </div>*/}
      {/*  <SectionSubscribe2 className="py-24 lg:py-32" />*/}
      {/*</div>*/}
    </div>
  );
};

export default PageContact;
