import React from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import Avatar from "../../shared/Avatar/Avatar";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import { useDeleteSponsorMutation, useGetSponsorsQuery } from "redux/syndicationListApi";
import { useNavigate } from "react-router-dom";

const Sponsor = () => {
  const navigate = useNavigate();
  const { data: sponsorData } = useGetSponsorsQuery({});
  const [deleteSponsor] = useDeleteSponsorMutation();

  const handleEdit = (data: any) => {
    navigate('/sponsor/edit', { state: data });
  };

  const handleDelete = async (id: string) => {
    await deleteSponsor(id).unwrap().then(() => {
    }).catch((err) => {
      console.log(err);
    });
  };

  const renderManagement = () => {
    return (
      <div className="space-y-6 sm:space-y-8">
        <div>
          <h2 className="text-3xl font-semibold">Sponsor</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {Array.isArray(sponsorData) && sponsorData.length === 0 ? null
          : (
            sponsorData?.map((sponsor: any) => (
              <div key={sponsor?.title}>
                <div key={sponsor?.title} className="listingSection__wrap rounded bg-white">
                  <div key={sponsor?.title}>
                    <div className="flex items-center space-x-4">
                      <Avatar
                        imgUrl={sponsor?.logo}
                        hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
                        sizeClass="h-14 w-14"
                        radius="rounded-full"
                      />
                      <div>
                        <a className="block text-xl font-medium">
                          {sponsor?.title}
                        </a>
                      </div>
                    </div>

                    <span className="block text-neutral-6000 dark:text-neutral-300 pt-4">
                      {sponsor?.description}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row pt-6">
                  <ButtonSecondary
                    className="w-full md:w-auto md:mr-3 mb-3 md:mb-0"
                    onClick={() => handleEdit(sponsor)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                      />
                    </svg>
                    <span className="ml-3">Edit Sponsor</span>
                  </ButtonSecondary>
                  <ButtonSecondary
                    className="w-full md:w-auto"
                    onClick={() => handleDelete(sponsor?.id)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                    <span className="ml-3">Delete Sponsor</span>
                  </ButtonSecondary>
                </div>
              </div>
            ))
          )}
        {Array.isArray(sponsorData) && sponsorData?.length > 0 ? null :
          <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            <ButtonPrimary href="/sponsor/add">Add Sponsor</ButtonPrimary>
          </div>}
      </div>
    );
  };

  return (
    <div>
      <CommonLayout>{renderManagement()}</CommonLayout>
    </div>
  );
};

export default Sponsor;
