import React, { useEffect } from "react";
import MyRouter from "routers/index";
import { useTypedDispatch } from './redux/store';
import userPool from "hooks/userPool";
import authSlice from "redux/authSlice";
import { setGlobalStyles } from "styles/globalStyles";

function App() {
  const dispatch = useTypedDispatch();
  const currentUser = userPool.getCurrentUser();

  useEffect(() => {
    const removeStyles = setGlobalStyles();
    return () => {
      removeStyles();
    };
  }, []);

  // get token
  useEffect(() => {
    if (currentUser) {
        currentUser.getSession((err: any, session: any) => {
          if (err) {
            console.log(err, "err");
          }
          if (session.isValid()) {
            // get token
            const accessToken = session.getAccessToken().getJwtToken();
            dispatch(
              authSlice.actions.setAccessToken({ accessToken: accessToken })
              );
              dispatch(
              authSlice.actions.setSignedIn({ signedIn: true })
              );
          }
        });
      
    } else {
      dispatch(
        authSlice.actions.setAccessToken({ accessToken: null })
      );
    }
  }, [currentUser]);
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
    </div>
  );
}

export default App;
