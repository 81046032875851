import React, { FC, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import cognitoService from "service/cognito";
import authSlice from "redux/authSlice";
import { useTypedDispatch } from "redux/store";
import { notificationSlice } from "redux/notificationSlice";
import IsLoading from "../../components/IsLoading";

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const dispatch = useTypedDispatch()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const SubmitForm = async (event: any) => {
    try {
      setIsLoading(true);
      event.preventDefault();
      const res = await cognitoService.signIn(email, password);
      dispatch(
        authSlice.actions.setAccessToken({
          accessToken: res.getAccessToken().getJwtToken(),
        })
      )
      dispatch(
        authSlice.actions.setSignedIn({
          signedIn: true,
        })
      )
      // will redirect user back to the page they tried to access
      navigate(location.state?.from || '/', { replace: true });
    } catch (err) {
      // dispatch(
      //   notificationSlice.actions.setNotification({
      //     message: err as string,
      //     status: "error",
      //   })
      // )
    }
  }



  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login | RentStream</title>
      </Helmet>
      {isLoading && (
        <IsLoading />
      )}
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Welcome back
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={SubmitForm}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/forgot-password" className="text-teal-600">
                  Forgot password?
                </Link>
              </span>
              <Input type="password" className="mt-1" value={password} onChange={(event) => setPassword(event.target.value)} />
            </label>
            {isLoading ? <ButtonPrimary loading>Continue</ButtonPrimary> : <ButtonPrimary type="submit">Continue</ButtonPrimary>}
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Don't have an account? {` `}
            <Link className="text-teal-600" to="/signup">Create your free account</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
