import { ListingGalleryImage } from "components/ListingImageGallery/utils/types";

export const PHOTOS: string[] = [
  "https://firebasestorage.googleapis.com/v0/b/rentstream-web.appspot.com/o/5601-Hamill-Rd-Houston-TX-IMG_1588-10-LargeHighDefinition.jpg?alt=media&token=438399d6-6b71-416e-ba8b-95c2e318bb2a",
  "https://firebasestorage.googleapis.com/v0/b/rentstream-web.appspot.com/o/5601-Hamill-Rd-Houston-TX-2sYqM8W2KJ_1666045086928_Use1-2-LargeHighDefinition.jpg?alt=media&token=248e7d0c-4022-49c0-87b8-f83805a09d35",
  "https://firebasestorage.googleapis.com/v0/b/rentstream-web.appspot.com/o/5601-Hamill-Rd-Houston-TX-IMG_1593-9-LargeHighDefinition.jpg?alt=media&token=19fbfc1a-8c1c-47d7-8016-b689782048b4",
    "https://firebasestorage.googleapis.com/v0/b/rentstream-web.appspot.com/o/5601-Hamill-Rd-Houston-TX-gjEhjbODri_1666045086916_Use4-4-LargeHighDefinition.jpg?alt=media&token=eda434d5-7458-461f-bb31-238deafdbc25",
  "https://firebasestorage.googleapis.com/v0/b/rentstream-web.appspot.com/o/5601-Hamill-Rd-Houston-TX-3oEiHmh7P3_1666045066630_Moey-1-Large.jpg?alt=media&token=fb58bbe8-07d0-4a16-9899-074b8fa1d25f"
];

export const Amenities_demos = [
  { name: "la-key", icon: "la-key" },
  { name: "la-luggage-cart", icon: "la-luggage-cart" },
  { name: "la-shower", icon: "la-shower" },
  { name: "la-smoking", icon: "la-smoking" },
  { name: "la-snowflake", icon: "la-snowflake" },
  { name: "la-spa", icon: "la-spa" },
  { name: "la-suitcase", icon: "la-suitcase" },
  { name: "la-suitcase-rolling", icon: "la-suitcase-rolling" },
  { name: "la-swimmer", icon: "la-swimmer" },
  { name: "la-swimming-pool", icon: "la-swimming-pool" },
  { name: "la-tv", icon: "la-tv" },
  { name: "la-umbrella-beach", icon: "la-umbrella-beach" },
  { name: "la-utensils", icon: "la-utensils" },
  { name: "la-wheelchair", icon: "la-wheelchair" },
  { name: "la-wifi", icon: "la-wifi" },
  { name: "la-baby-carriage", icon: "la-baby-carriage" },
  { name: "la-bath", icon: "la-bath" },
  { name: "la-bed", icon: "la-bed" },
  { name: "la-briefcase", icon: "la-briefcase" },
  { name: "la-car", icon: "la-car" },
  { name: "la-cocktail", icon: "la-cocktail" },
  { name: "la-coffee", icon: "la-coffee" },
  { name: "la-concierge-bell", icon: "la-concierge-bell" },
  { name: "la-dice", icon: "la-dice" },
  { name: "la-dumbbell", icon: "la-dumbbell" },
  { name: "la-hot-tub", icon: "la-hot-tub" },
  { name: "la-infinity", icon: "la-infinity" },
];

export const imageGallery: ListingGalleryImage[] = [...PHOTOS].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);
