import React, { FC, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import cognitoService from "service/cognito";
import IsLoading from "../../components/IsLoading";

export interface PageSignUpProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const SubmitForm = async (event: any) => {
    setIsLoading(true);
    event.preventDefault();
    await cognitoService.signUp(email, password, email);
    navigate("/account")
    setIsLoading(false);
  }


  return (
    <div className={`nc-PageSignUp  ${className}`}>
      <Helmet>
        <title>Join | RentStream</title>
      </Helmet>
      {isLoading && (
        <IsLoading />
      )}
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Let's get started
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">

          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={SubmitForm}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input type="password" className="mt-1" value={password}
                onChange={(event) => setPassword(event.target.value)} />
            </label>
            {isLoading ? <ButtonPrimary loading>Sign Up</ButtonPrimary> : <ButtonPrimary type="submit">Sign Up</ButtonPrimary>}
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link className="text-teal-600" to="/login">Log In</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
