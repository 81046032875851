import React, { useEffect } from "react";
import SectionHero2 from "components/SectionHero2/SectionHero2";
//
import Heading from "../../components/Heading/Heading";
import rightImg from "../../images/acquisition-strategy.jpg";
import leftImg from "../../images/valueadd-strategy.jpg";

function PageStrategy() {
    // CUSTOM THEME STYLE
    useEffect(() => {
        const $body = document.querySelector("body");
        if (!$body) return;
        $body.classList.add("theme-cyan-blueGrey");
        return () => {
            $body.classList.remove("theme-cyan-blueGrey");
        };
    }, []);

    return (
        <div className="nc-PageHome2 relative overflow-hidden">
            <div className="container relative lg:mb-28">
                <SectionHero2 className="" />

                {/* SECTION2 */}
                <div className="relative py-16 text-center bg-blue-50">
                    <div className={"container"}>
                        <Heading isCenter>Low-Risk, Higher Returns</Heading>
                        <p className="font-light text-lg text-neutral-600 lg:px-16 mb-12">RentStream uses its 100% cash buying strengths to its advantage to acquire and enhance Class B & C multifamily communities nationwide. Class B & C properties present a highly appealing investment prospect because of the disparity between the strong and expanding demand for these units and their limited new supply. In addition, multifamily has traditionally been a less volatile real estate asset category during downturns while still having considerable upside potential during upcycles. </p>
                        <a className="bg-orange-500 text-white px-4 py-3 sm:px-6 text-sm sm:text-base font-medium rounded" href={"/signup"}>Invest Now</a>
                    </div>
                </div>

                {/* SECTION 1 */}
                <div className="relative py-16">
                    <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-24">
                        <div className="w-screen max-w-full xl:max-w-2xl space-y-5 lg:space-y-7">
                            <Heading>Acquisition Strategy</Heading>
                            <div className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 prose">Using our 100% cash, $0 debt strategy, we identify undervalued multifamily properties for acquisition, value optimizations, management, and disposition.<br/><br/>
                                <strong>MARKET SEGMENTS</strong>
                                <ul>
                                    <li>Age: The 18-35 year old market segment is 22% of the U.S. population</li>
                                    <li>Income: Renters who earn $40,000 or more annually</li>
                                    <li>Price: Where rent is 30% or less of the median income</li>
                                </ul>
                                <strong>PROPERTY CRITERIA</strong>
                                <ul>
                                    <li>Multifamily residential apartments</li>
                                    <li>Occupancy above 80% with the exception of properties that require renovation, providing properties are well located and present value-add opportunities</li>
                                </ul>
                                <strong>TARGET VALUES</strong>
                                <ul>
                                    <li>Size and Price: 30+ units in the $2MM – $30MM range</li>
                                    <li>Returns: 12-15% Cash on Cash</li>
                                    <li>Type: C- to B+ properties located in C- to A areas</li>
                                    <li>Property Vintage: 1975 or newer</li>
                                    <li>Location: Areas with indicators for strong near and long-term economic growth</li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-96 flex-grow hidden sm:block">
                            <img src={rightImg} alt="" />
                        </div>
                    </div>
                </div>
                <div className="relative lg:py-16 pb-16 lg:pb-0">
                    <div className="flex flex-col lg:flex-row lg:space-y-0 lg:space-x-24">
                        <div className="w-96 flex-grow pb-16 lg:pb-0">
                            <img src={leftImg} alt="" />
                        </div>
                        <div className="w-screen max-w-full xl:max-w-2xl space-y-5 lg:space-y-7">
                            <Heading>Value-Add Strategy</Heading>
                            <div className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 prose">When we purchase an apartment complex, we are looking for specific opportunities to increase the cashflow in different areas.<br/><br/>
                                <strong>OPERATION</strong>
                                <ul>
                                    <li>Mismanagement caused by owner self-managing</li>
                                    <li>Poor supervision of management companies</li>
                                    <li>Deferred maintenance</li>
                                </ul>
                                <strong>PROPERTY</strong>
                                <ul>
                                    <li>High vacancies</li>
                                    <li>Below market rents</li>
                                </ul>
                                <strong>TARGET VALUE-ADDS</strong>
                                <ul>
                                    <li>Improve curb appeal by improving landscaping, adding dog parks, carports, etc. Residents will pay more when a property is in better condition and has amenities.</li>
                                    <li>Purchasing a property that is 10% or more under current market rents. This allows us to increase rents and immediately increase the property's value.</li>
                                    <li>Implement a water and sewage bill-back system to charge the residents for actual usage. Most apartment owners pay for all the water. Billing back the residents helps offset expenses and increase the cash flow. Through this system, residents tend to become more frugal and will decrease overall operating expenses.</li>
                                    <li>Improve unit interiors with new paint, appliances, countertops, and floors.</li>
                                    <li>Adding a coin laundry facility to the complex.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default PageStrategy;
