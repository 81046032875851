import Label from "components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import { useGetUserQuery, useUpdateUserMutation } from "redux/syndicationListApi";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, SubmitHandler } from "react-hook-form";
import { usStates } from "../../utils/types";
import IsLoading from "components/IsLoading";
import { useSetProfileImageMutation } from "redux/syndicationListApi";
import Avatar from "shared/Avatar/Avatar";

export interface AccountPageProps {
  className?: string;
}
interface FormValues {
  preventDefault?: any;
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
  city?: string;
  state?: string;
  phoneNumber?: string;
  accreditedInvestor?: boolean | string;
}

const schema = yup.object().shape({
  firstName: yup.string().required('First name is required').min(2, 'Last name must be at least 2 characters'),
  lastName: yup.string().required('Last name is required').min(2, 'Last name must be at least 2 characters'),
  email: yup.string().matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Email is not valid').required('Email is required'),
  phoneNumber: yup.string()
    .nullable()
    .test('valid-phone-number', 'Phone number is required', function (value) {
      if (!value) {
        // if value is null, the validation will be skipped
        return true;
      }
      return /^[0-9]{10}$/.test(value.replace(/-/g, ''));
    }),
});

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const { data: userData, isLoading: initialLoad } = useGetUserQuery({});
  const [setProfileImage] = useSetProfileImageMutation();
  const [updateUser] = useUpdateUserMutation();
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormValues>({
    resolver: yupResolver(schema)
  });

  const onSubmit: SubmitHandler<FormValues> = async (data: any) => {
    setIsLoading(true);
    // convert accreditedInvestor to boolean
    data.accreditedInvestor = data.accreditedInvestor === "true" ? true : false;
    // remove dashes from phone number
    data.phoneNumber = data.phoneNumber.replace(/-/g, "");
    await updateUser(data).unwrap().then(() => {
      setIsLoading(false);
    }).catch((error: any) => {
      console.log(error, 'error in update user');
      setIsLoading(false);
      throw error;
    });
  };

  const submitFile = async (e: any) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    await setProfileImage(formData).unwrap().then(() => {
      setIsLoading(false);
    }).catch((error: any) => {
      console.log(error, 'error in set profile image');
      setIsLoading(false);
      throw error;
    });
  }

  useEffect(() => {
    if (initialLoad) {
    } else {
      setValue('firstName', userData?.firstName);
      setValue('lastName', userData?.lastName);
      setValue('username', userData?.username);
      setValue('email', userData?.email);
      setValue('city', userData?.city);
      setValue('state', userData?.state);
      setValue('phoneNumber', userData?.phoneNumber);
      setValue('accreditedInvestor', userData?.accreditedInvestor === true ? "true" : "false");
    }
  }, [initialLoad]);


  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account | RentStream</title>
      </Helmet>
      {isLoading && (
        <IsLoading />
      )}
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Account Information</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-shrink-0 flex items-start">
              <div className="relative rounded-full overflow-hidden flex">
                <Avatar sizeClass="w-32 h-32" imgUrl={userData?.profilePicture} />
                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="mt-1 text-xs">Change Image</span>
                </div>
                <input
                  onChange={(e) => submitFile(e)}
                  type="file"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                />
              </div>
            </div>
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex justify-between gap-4">
                  <div className="w-full">
                    <Label>First name</Label>
                    <Input placeholder="First name" className={`mt-1.5 ${errors.firstName ? "border-red-500" : ""}`} {...register('firstName')} />
                    {errors.firstName && <span className="text-red-500">{errors.firstName.message}</span>}
                  </div>
                  <div className="w-full">
                    <Label>Last name</Label>
                    <Input placeholder="Last name" className={`mt-1.5 ${errors.lastName && 'border-red-500'}`} {...register('lastName')} />
                    {errors.lastName && <span className="text-red-500">{errors.lastName.message}</span>}
                  </div>
                </div>

                <div className="pt-4">
                  <Label>Username</Label>
                  <Input placeholder="Username" className={`mt-1.5 ${errors.username && 'border-red-500'}`} {...register('username')} />
                  {errors.username && <span className="text-red-500">{errors.username.message}</span>}
                </div>

                <div className="pt-4">
                  <Label>Email</Label>
                  <Input placeholder="Email" className={`mt-1.5 ${errors.email && 'border-red-500'}`} {...register('email')} />
                  {errors.email && <span className="text-red-500">{errors.email.message}</span>}
                </div>

                <div className="flex justify-between gap-4 pt-4">
                  <div className="w-full">
                    <Label>City</Label>
                    <Input placeholder="City" className={`mt-1.5 ${errors.city && 'border-red-500'}`} {...register('city')} />
                    {errors.city && <span className="text-red-500">{errors.city.message}</span>}
                  </div>
                  <div className="w-full">
                    <Label>State</Label>
                    <Select className={`mt-1.5 ${errors.state ? 'border-red-500' : ''}`} {...register('state')}>
                      <option value="">Select a state</option>
                      {usStates.map((state) => {
                        return (
                          <option key={state.value} value={state.value}>
                            {state.label}
                          </option>
                        );
                      })}
                    </Select>
                    {errors.state && <span className="text-red-500">{errors.state.message}</span>}
                  </div>
                </div>

                <div className="pt-4">
                  <Label>Phone number</Label>
                  <Input placeholder="123-456-7890" className={`mt-1.5 ${errors.phoneNumber && 'border-red-500'}`} defaultValue="0000000000" {...register('phoneNumber')} />
                  {errors.phoneNumber && <span className="text-red-500">{errors.phoneNumber.message}</span>}
                </div>

                <div className="pt-4">
                  <Label>Are you an accredited investor?</Label>
                  <Select className="mt-1.5" {...register('accreditedInvestor')}>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Select>
                  {errors.accreditedInvestor && <span className="text-red-500">{errors.accreditedInvestor.message}</span>}
                </div>

                <div className="pt-8">
                  {isLoading ? <ButtonPrimary loading type="submit">Update Info</ButtonPrimary> : <ButtonPrimary type="submit">Update Info</ButtonPrimary>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
