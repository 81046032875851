import React, { useState } from "react";
import FormItem from "../PageAddListing/FormItem";
import Input from "../../shared/Input/Input";
import IsLoading from "../../components/IsLoading";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";

const ValuationCalculator: React.FC = () => {
    const [purchasePrice, setPurchasePrice] = useState<number>(0);
    const [downPayment, setDownPayment] = useState<number>(0);
    const [interestRate, setInterestRate] = useState<number>(0);
    const [loanTerm, setLoanTerm] = useState<number>(0);
    const [netOperatingIncome, setNetOperatingIncome] = useState<number>(0);
    const [debtService, setDebtService] = useState<number>(0);
    const [debtServiceCoverageRatio, setDebtServiceCoverageRatio] = useState<number>(0);
    const [capRate, setCapRate] = useState<number>(0);
    const [cashOnCashReturn, setCashOnCashReturn] = useState<number>(0);

    const calculateValuation = () => {
        const loanAmount = purchasePrice - downPayment;
        const monthlyInterestRate = interestRate / 100 / 12;
        const numberOfPayments = loanTerm * 12;
        const monthlyPayment = (loanAmount * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));
        setDebtService(monthlyPayment * 12);
        setDebtServiceCoverageRatio(netOperatingIncome / (monthlyPayment * 12));
        setCapRate((netOperatingIncome / purchasePrice) * 100);
        const annualCashFlow = netOperatingIncome - debtService;

        setCashOnCashReturn((annualCashFlow / downPayment) * 100);
    };
    const [isLoading, setIsLoading] = useState<boolean>(false);

    return (
        <div
            className="px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32"
        >
            {isLoading && (
                <IsLoading />
            )}
            <h2 className="text-2xl font-semibold mb-10">Valuation Calculator</h2>

                <div className="listingSection__wrap rounded">
                    <div className="flex justify-between gap-4">
                        <div className="w-full">
                            <p>DSCR:</p>
                            <h2 className="text-2xl font-semibold">{debtServiceCoverageRatio.toFixed(2)}</h2>
                        </div>
                        <div className="w-full">
                            <p>Cap Rate:</p>
                            <h2 className="text-2xl font-semibold">{capRate.toFixed(2)}%</h2>
                        </div>
                        <div className="w-full">
                            <p>ROI:</p>
                            <h2 className="text-2xl font-semibold">{cashOnCashReturn.toFixed(2)}%</h2>
                        </div>
                    </div>
                    <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="flex justify-between gap-4">
                        <FormItem label="Purchase Price" className="w-full">
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500">$</span>
                                </div>
                                <Input          id="purchasePrice"
                                                value={purchasePrice}
                                                onChange={(e) => setPurchasePrice(Number(e.target.value))} className="!pl-8 !pr-10" placeholder="0.00" />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500">USD</span>
                                </div>
                            </div>
                        </FormItem>
                        <FormItem label="Down Payment" className="w-full">
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500">$</span>
                                </div>
                                <Input className="!pl-8 !pr-10"           id="downPayment"
                                       value={downPayment}
                                       onChange={(e) => setDownPayment(Number(e.target.value))}/>
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500">USD</span>
                                </div>
                            </div>
                        </FormItem>
                    </div>
                    <div className="flex justify-between gap-4">
                        <FormItem label="Interest Rate" className="w-full">
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500">%</span>
                                </div>
                                <Input id="interestRate" value={interestRate} onChange={(e) => setInterestRate(Number(e.target.value))} className="!pl-8 !pr-10" />
                            </div>
                        </FormItem>
                        <FormItem label="Term" className="w-full">
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500"></span>
                                </div>
                                <Input className="!pl-8 !pr-10"           id="loanTerm"
                                       value={loanTerm}
                                       onChange={(e) => setLoanTerm(Number(e.target.value))}/>
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500">YEARS</span>
                                </div>
                            </div>
                        </FormItem>
                    </div>
                    <div className="space-y-8">
                        <FormItem label="Net Operating Income (NOI)">
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500">$</span>
                                </div>
                                <Input           id="netOperatingIncome"
                                                 value={netOperatingIncome}
                                                 onChange={(e) => setNetOperatingIncome(Number(e.target.value))} className="!pl-8 !pr-10" placeholder="0.00" />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500">USD</span>
                                </div>
                            </div>
                        </FormItem>
                    </div>
                    <div className="flex space-x-5">
                        <ButtonPrimary onClick={calculateValuation}>
                            Calculate Valuation Metrics
                        </ButtonPrimary>
                    </div>
                    <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="flex gap-6">
                        <div>
                            <p className="text-neutral-500 text-sm">Annual Debt Service</p>
                            <h2 className="text-1xl font-semibold">${debtService.toFixed(2)}</h2>
                        </div>
                        <div>
                            <p className="text-neutral-500 text-sm">Annual Cash Flow</p>
                            <h2 className="text-1xl font-semibold">${(netOperatingIncome - debtService).toFixed(2)}</h2>
                        </div>
                    </div>
                </div>
            <div
                id="single-entry-content"
                className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark pt-10"
            >
                <p><strong>Debt Service Coverage Ratio (DSCR)</strong> is a financial metric used to evaluate the ability of a business or real estate investment to generate enough cash flow to cover its debt payments. It is calculated by dividing the property's net operating income (NOI) by its annual debt service (the total amount of principal and interest payments on the property's debt in a year).</p>

                <p>DSCR is expressed as a ratio, with a higher ratio indicating a lower risk of default on the property's debt. A DSCR of 1 or greater means that the property's NOI is sufficient to cover its debt payments, while a DSCR below 1 means that the property's cash flow is insufficient to cover its debt payments.</p>

                <p>Lenders and investors use DSCR as a key factor in assessing the financial viability of a real estate investment. Generally, lenders require a minimum DSCR of 1.2 or higher to consider a loan for a commercial property. A higher DSCR not only indicates lower default risk but also allows for a higher amount of debt to be taken on for the investment.:</p>

                <p><strong>Cap Rate</strong>, short for capitalization rate, is a financial metric used to evaluate the value of a real estate investment. It represents the expected rate of return on an investment property based on the net operating income (NOI) generated by the property.</p>

                <p>Cap Rate is calculated by dividing the property's NOI by its market value or purchase price. It is expressed as a percentage, and a higher Cap Rate indicates a higher potential return on investment. A lower Cap Rate, on the other hand, indicates a lower potential return on investment.</p>

                <p>Cap Rate is used by real estate investors to compare the profitability of different investment properties. It is also used by appraisers and lenders to determine the value of a property based on its income-generating potential. A higher Cap Rate is generally associated with a riskier investment, while a lower Cap Rate is associated with a more stable investment.</p>

                <p><strong>Return on Investment (ROI)</strong> or cash on cash return (CCR) is a financial metric used to evaluate the cash income earned on an investment in relation to the amount of cash invested. It is calculated by dividing the property's annual pre-tax cash flow by the amount of cash invested.</p>

                <p>For real estate investments, the cash invested typically includes the down payment, closing costs, and any other upfront expenses. The annual pre-tax cash flow is the net operating income (NOI) minus the annual debt service.</p>
            </div>
        </div>


    );
};

export default ValuationCalculator;
