import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/hero-right.png";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-medium text-4xl md:text-4xl xl:text-6xl !leading-[114%] ">
            The safest way to invest in commercial real estate
          </h2>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            We purchase every property with <strong className={'text-primary-6000'}>100% cash and $0 in debt</strong>. With this simple strategy, we are good stewards of our investors' capital and create shareholder value with a higher return on investment.
          </span>
          <ButtonPrimary href={'/signup'}>Get Started Now</ButtonPrimary>
        </div>
        <div className="flex-grow">
          <img className="w-full" src={imagePng} alt="hero" />
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
