import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import authSlice, { refreshAccessToken } from './authSlice';
import { REHYDRATE } from 'redux-persist';

const baseURL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_API_DEV_URL
    : process.env.REACT_APP_API_PROD_URL;

const baseQuery = fetchBaseQuery({
  prepareHeaders: async (headers, { getState }: any) => {
    const accessToken = getState().auth.accessToken
    headers.set(
      'Authorization',
      `Bearer ${accessToken}`
    );
    headers.set('Accept', 'application/json, text/plain, */*');
    return headers;
  },
});
const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  const dispatch = api.dispatch;
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error && result?.error?.status === 401) {
    // remove the old token
    api.dispatch(authSlice.actions.setAccessToken({ accessToken: null }));
    // try to get a new token
    const accessToken = await dispatch(refreshAccessToken());
    if (accessToken) {
      api.dispatch(authSlice.actions.setAccessToken(accessToken));
      api.dispatch(authSlice.actions.setSignedIn({ signedIn: true }));
      // retry the initial query/..
      result = await baseQuery(args, api, extraOptions);
    } else {
      // we can add a logout method here if we want
      // api.dispatch(loggedOut());
    }
  }
  return result;
};

type PingResponse = any;

export const SYNDICATION_API_REDUCER_KEY = 'syndicationListApi';
// Define a service using a base URL and expected endpoints
export const syndicationListApi = createApi({
  reducerPath: SYNDICATION_API_REDUCER_KEY,

  baseQuery: baseQueryWithReauth,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action?.type === REHYDRATE) {
      // below necessary for firefox
      if (!action?.payload) return undefined
      return action?.payload[reducerPath]
    }
  },
  tagTypes: [
    'Users',
    'User',
    'Sponsors',
  ],
  endpoints: (build) => ({
    // ------------------------------------ PING ------------------------------------
    pingServer: build.query<PingResponse, any>({
      query: () => ({
        url: `${baseURL}/`,
        method: 'GET',
      }),
    }),
    // ------------------------------------ Get User ------------------------------------
    getUser: build.query<any, any>({
      query: () => ({
        url: `${baseURL}/users`,
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
    // ------------------------------------ Update User ------------------------------------
    updateUser: build.mutation<any, any>({
      query: (body) => ({
        url: `${baseURL}/users`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    // ------------------------------------ Set Profile Image ------------------------------------
    setProfileImage: build.mutation<any, any>({
      query: (body) => ({
        url: `${baseURL}/users/upload`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    // ------------------------------------ Create Sponsor ------------------------------------
    createSponsor: build.mutation<any, any>({
      query: (body) => ({
        url: `${baseURL}/sponsors`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Sponsors'],
    }),
    // ------------------------------------ Get Sponsors ------------------------------------
    getSponsors: build.query<any, any>({
      query: () => ({
        url: `${baseURL}/sponsors`,
        method: 'GET',
      }),
      providesTags: (result) => [
        ...result?.map(({ id }: any) => ({ type: 'Sponsors', id })),
        'Sponsors',
      ],
    }),
    // ------------------------------------ Update Sponsor ------------------------------------
    updateSponsor: build.mutation<any, any>({
      query: (payload) => ({
        url: `${baseURL}/sponsors`,
        method: 'PATCH',
        body: payload.formData,
      }),
      async onQueryStarted(payload , { dispatch, queryFulfilled }) {
        const { data: updatedPost } = await queryFulfilled
        const updateSponsorsQueryData = (draftPosts: any[]) => {
          const id = updatedPost.id
          const index = draftPosts.findIndex((d: { id: any; }) => d.id === id);
          if (index > -1) {
            draftPosts[index] = updatedPost;
          }
          return draftPosts;
        };
        const patchResult = dispatch(syndicationListApi.util.updateQueryData('getSponsors', {}, updateSponsorsQueryData));
        try {
          await queryFulfilled;
        } catch {
          console.log('error')
          patchResult.undo();
        }
      },
    }),
    
    // ------------------------------------ Delete Sponsor ------------------------------------
    deleteSponsor: build.mutation<any, any>({
      query: (sponsorId) => ({
        url: `${baseURL}/sponsors/${sponsorId}`,
        method: 'DELETE',
      }),
    
      async onQueryStarted(sponsorId, { dispatch, queryFulfilled }) {
        const updateSponsorsQueryData = (draftPosts: any[]) => {
          const index = draftPosts.findIndex((d: { id: any; }) => d.id === sponsorId);
          if (index > -1) {
            draftPosts.splice(index, 1);
          }
          return draftPosts;
        };
        const patchResult = dispatch(syndicationListApi.util.updateQueryData('getSponsors', {}, updateSponsorsQueryData));
    
        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
          throw error;
        }
      }
    }),
  }),
});

export const {
  usePingServerQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useSetProfileImageMutation,
  useCreateSponsorMutation,
  useGetSponsorsQuery,
  useUpdateSponsorMutation,
  useDeleteSponsorMutation,
} = syndicationListApi;
