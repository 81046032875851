import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface InitialState {
  listingFormData?: ListingFormData;
}

const initialState: InitialState = {
    listingFormData: {
        title: undefined,
        propertyType: 'Choose Property Type',
        investmentProfile: undefined,
        address: {
            address1: undefined,
            address2: undefined,
            city: undefined,
            state: undefined,
            zip: undefined,
        },
        minimumInvestment: 0,
        targetedInvestmentPeriod: 0,
        rateOfReturn: 0,
        whoCanInvest: undefined,
        investmentState: undefined,
        overview: undefined,
        businessPlan: undefined,
        documents: [],
        pictures: [],
        sponsor: undefined,
        disclaimer: undefined,
    },
};
interface Address {
  address1: string | undefined;
  address2: string | undefined;
  city: string | undefined;
  state: string | undefined;
  zip: string | undefined;
}

interface Document {
  name: string;
  url: string;
}

interface Picture {
  name: string;
  url: string;
}
interface ListingFormData {
    title?: string;
    propertyType?: string;
    investmentProfile?: string;
  address?: Address;
  minimumInvestment?: number;
  targetedInvestmentPeriod?: number;
  rateOfReturn?: number;
  whoCanInvest?: string;
  investmentState?: string;
  overview?: string;
  businessPlan?: string;
  documents?: Document[];
  pictures?: Picture[];
  sponsor?: string;
  disclaimer?: string;
}

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setListingFormData: (state: InitialState, action: PayloadAction<any>) => {
      state.listingFormData = action.payload;
    },
    clearListingFormData: (state: InitialState) => {
      state.listingFormData = initialState.listingFormData;
    }
  },
});


export const { setListingFormData, clearListingFormData } = formSlice.actions;
export type AuthState = ReturnType<typeof formSlice.reducer>;
export default formSlice
