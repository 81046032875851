import React, { FC } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import ListingCard from "components/ListingCard/ListingCard";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: StayDataType[];
}

const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

const SectionGridListings: FC<SectionGridFilterCardProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridListings"
    >
      <div className="grid grid-cols-1 gap-6 md:gap-6 xl:grid-cols-4 ">
        {data.map((stay) => (
          <ListingCard key={stay.id} data={stay} />
        ))}
      </div>
      {/*<div className="flex mt-16 justify-center items-center relative">*/}
      {/*  <Pagination />*/}
      {/*</div>*/}
    </div>
  );
};

export default SectionGridListings;
