export const setGlobalStyles = () => {
  const $body = document.querySelector("body");
  if ($body) {
    $body.className = "theme-teal-blueGrey";
  }
  return () => {
    if ($body) {
      $body.className = "";
    }
  };
};
