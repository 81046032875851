import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate, RouteObject, useLocation } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageLandingPage/LandingPage";
import Page404 from "containers/Page404/Page404";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
// import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
// import AccountSavedListings from "containers/AccountPage/AccountSavedListings";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubscription from "containers/PageSubscription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import SiteHeader from "containers/SiteHeader";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import { useTypedSelector } from "redux/store";
import ListingDetailPage from "containers/ListingDetailPage/listing-detail/ListingDetailPage";
import PageForgotPassword from "../containers/PageForgotPassword/PageForgotPassword";
import Page500 from "containers/PageError/PageError";
import { ErrorBoundary } from "react-error-boundary";
import AccountListings from "../containers/AccountPage/AccountListings";
import Sponsor from "../containers/AccountPage/Sponsor";
import AddSponsor from "../containers/PageSponsor/AddSponsor";
import PrivacyPage from "../containers/PrivacyPage/PrivacyPage";
import IsLoading from "components/IsLoading";
import AccountSavedListings from "containers/AccountPage/AccountSavedListings";
import LoanToValueCalculator from "../containers/LoanToValueCalculator/LoanToValueCalculator";
import ValuationCalculator
    from "../containers/ValuationCalculator/ValuationCalculator";
import PageStrategy from "../containers/PageStrategy/PageStrategy";
const PageAddListing1 = lazy(() => import('containers/PageAddListing/PageAddListing1'));
const PageAddListing2 = lazy(() => import('containers/PageAddListing/PageAddListing2'));
const PageAddListing3 = lazy(() => import('containers/PageAddListing/PageAddListing3'));
const PageAddListing4 = lazy(() => import('containers/PageAddListing/PageAddListing4'));
const PageAddListing5 = lazy(() => import('containers/PageAddListing/PageAddListing5'));
const PageAddListing6 = lazy(() => import('containers/PageAddListing/PageAddListing6'));
const PageAddListing7 = lazy(() => import('containers/PageAddListing/PageAddListing7'));
const PageAddListing8 = lazy(() => import('containers/PageAddListing/PageAddListing8'));
const PageAddListing9 = lazy(() => import('containers/PageAddListing/PageAddListing9'));
const PageAddListing10 = lazy(() => import('containers/PageAddListing/PageAddListing10'));

type ExtendedRouteObject = RouteObject & {
  exact?: boolean;
};

const protectedRoutes: ExtendedRouteObject[] = [
  { path: "/account", element: <AccountPage /> },
  { path: "/my-listings", element: <AccountListings /> },
  { path: "/account-password", element: <AccountPass /> },
  { path: "/account-billing", element: <AccountBilling /> },
  { path: "/saved-listings", element: <AccountSavedListings /> },
  // sponsor
  {
    path: "/sponsor/*",
    exact: true,
    element: (
      <Routes>
        <Route path="/" element={<Sponsor />} />
        <Route path="add" element={<AddSponsor />} />
        <Route path="edit" element={<AddSponsor />} />
      </Routes>
    ),
  },
  {
    // default route to redirect to the first step of the add listing process
    path: "/add-listing/*",
    exact: true,
    element: (
      <Routes>
        <Route path="/" element={<Navigate to="/add-listing/1" />} />
        <Route path="1" element={<PageAddListing1 />} />
        <Route path="2" element={<PageAddListing2 />} />
        <Route path="3" element={<PageAddListing3 />} />
        <Route path="4" element={<PageAddListing4 />} />
        <Route path="5" element={<PageAddListing5 />} />
        <Route path="6" element={<PageAddListing6 />} />
        <Route path="7" element={<PageAddListing7 />} />
        <Route path="8" element={<PageAddListing8 />} />
        <Route path="9" element={<PageAddListing9 />} />
        <Route path="10" element={<PageAddListing10 />} />
      </Routes>
    ),
  },
]

export const publicRoutes: ExtendedRouteObject[] = [
  { path: "/", element: <PageHome />, exact: true },
  { path: "/home-1-header-2", element: <PageHome /> },
  { path: "/listing-detail", element: <ListingDetailPage /> },
  // { path: "/checkout", element: <CheckOutPage /> },
  // { path: "/pay-done", element: <PayPage /> },
  // { path: "/blog", element: <BlogPage /> },
  // { path: "/blog-single", element: <BlogSingle /> },
  { path: "/contact", element: <PageContact /> },
    { path: "/strategy", element: <PageStrategy /> },
  { path: "/about", element: <PageAbout /> },
  { path: "/signup", element: <PageSignUp /> },
  { path: "/login", element: <PageLogin /> },
  { path: "/forgot-password", element: <PageForgotPassword /> },
  // { path: "/subscription", element: <PageSubscription /> },
  { path: "/privacy", element: <PrivacyPage />},
    { path: "/loan-to-value-ratio-calculator", element: <LoanToValueCalculator /> },
    {path: "/valuation-calculator", element: <ValuationCalculator/>},
  { path: "/page500", element: <Page500 /> },
];

const ProtectedRoute = ({ element, ...props }: any) => {
  const signedIn = useTypedSelector((state: any) => state.auth.signedIn);
  const location = useLocation();

  // If the user is not signed in, redirect to the login page with the current location as the "from" state
  if (!signedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return element;
};

const MyRoutes = (): JSX.Element => {
  const { width: WIN_WIDTH } = useWindowSize() || {};
  const isLoggedIn = useTypedSelector((state: any) => state.auth.signedIn);

  return (
    <BrowserRouter>
      {/* Catch errors and show a 500 page */}
      <ErrorBoundary
        FallbackComponent={Page500}
        onReset={(details) => {
          // Reset the state of your app so the error doesn't happen again
        }}
      >
        {/* Scroll to the top of the page on route changes */}
        <ScrollToTop />

        {/* Render the site header */}
        <SiteHeader />

        {/* Render the public and protected routes */}
        <Suspense fallback={<IsLoading />}>
          <Routes>
            {publicRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  isLoggedIn && (route.path === '/signup' || route.path === '/login') ? (
                    <Navigate to="/" replace />
                  ) : (
                    route.element
                  )
                }
              />
            ))}

            {protectedRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<ProtectedRoute element={route.element} />}
              />
            ))}
            <Route path="*" element={<Page404 />} />
          </Routes>
        </Suspense>

        {/* Render the footer navigation on small screens */}
        {WIN_WIDTH < 768 && <FooterNav />}

        {/* Render the site footer */}
        <Footer />
      </ErrorBoundary>
    </BrowserRouter>
  );
};


export default MyRoutes;
