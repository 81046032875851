import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import userPool from 'hooks/userPool';
import { PURGE } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
import { syndicationListApi } from './syndicationListApi';
import cognitoService from 'service/cognito';

export interface InitialState {
  accessToken: string | null;
  signedIn: boolean;
}

const initialState: InitialState = {
  accessToken: null,
  signedIn: false,
};

export interface TokenPayload {
  accessToken: string | null;
}

interface SignedInPayload {
  signedIn: boolean;
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (state: InitialState, action: PayloadAction<TokenPayload>) => {
      state.accessToken = action.payload.accessToken;
    },
    logoutUser: (state: InitialState) => {
      state.accessToken = '';
      state.signedIn = false;
      setAccessToken({ accessToken: '' });
      syndicationListApi.util.resetApiState();
      localStorage.removeItem('persist:root');
      cognitoService.signOut();
    },
    setSignedIn: (state: InitialState, action: PayloadAction<SignedInPayload>) => {
      state.signedIn = action.payload.signedIn ? action.payload.signedIn : false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(refreshAccessToken.pending, (state) => {
      // Set the access token to an empty string while the refresh is pending
      state.accessToken = '';
    });
    builder.addCase(refreshAccessToken.fulfilled, (state, action) => {
      // Set the new access token when the refresh is successful
      state.accessToken = action.payload.accessToken;
    });
    builder.addCase(PURGE, (state) => {
      localStorage.removeItem('persist:root');
  });
  },
});

const refreshAccessToken = createAsyncThunk<any, void, {state: InitialState}>(
  'auth/refreshAccessToken',
  async (_, { getState, dispatch }) => {
    const { accessToken } = getState()
    if (!accessToken) {
      throw new Error('User not authenticated');
    }

    const user = userPool.getCurrentUser();
    console.log(user, 'user')

    if (!user) {
      throw new Error('User not authenticated');
    }

    const session: CognitoUserSession = await new Promise((resolve, reject) => {
      user.getSession((err: any, session: CognitoUserSession | PromiseLike<CognitoUserSession>) => {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    });

    const refreshedSession: CognitoUserSession = await new Promise((resolve, reject) => {
      user.refreshSession(session.getRefreshToken(), (err, session) => {
        if (err) {
          reject(err);
        } else {
          dispatch(setAccessToken(session.getAccessToken().getJwtToken()));
          resolve(session);
        }
      });
    });

    console.log(refreshedSession.getAccessToken().getJwtToken(), 'refreshedSession.getAccessToken().getJwtToken()')

    return { accessToken: refreshedSession.getAccessToken().getJwtToken() || null };
  },
);

export const { setAccessToken, logoutUser, setSignedIn } = authSlice.actions;
export { refreshAccessToken };
export type AuthState = ReturnType<typeof authSlice.reducer>;
export default authSlice
