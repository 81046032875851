import React, { FC, useCallback, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import FormItem from "containers/PageAddListing/FormItem";
import ButtonSecondary from "../../shared/Button/ButtonSecondary";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import Textarea from "../../shared/Textarea/Textarea";
import { useCreateSponsorMutation, useDeleteSponsorMutation, useUpdateSponsorMutation } from "redux/syndicationListApi";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, SubmitHandler } from "react-hook-form";
import { Helmet } from "react-helmet";
import { useDropzone } from 'react-dropzone'
import { useLocation, useNavigate } from "react-router-dom";
import IsLoading from "components/IsLoading";


export interface AddSponsorProps { }

interface FormValues {
    logoFile: File
    title: string;
    description?: string;
}

const schema = yup.object().shape({
    logoFile: yup
        .mixed<any>()
        .test(
            "fileSize",
            "File size is greater than 10MB",
            (value) => value && value.size <= 10000000
        )
        .required("A file is required"),
    title: yup.string().required('Last name is required').min(2, 'Last name must be at least 2 characters'),
    description: yup.string().required('Description is required').min(2, 'Description must be at least 2 characters'),
});

const updateSchema = yup.object().shape({
    title: yup.string().required('Last name is required').min(2, 'Last name must be at least 2 characters'),
    description: yup.string().required('Description is required').min(2, 'Description must be at least 2 characters'),
});

const AddSponsor: FC<AddSponsorProps> = () => {
    const location = useLocation();
    const [logoUrl, setLogoUrl] = useState<string | null>(null);
    const [createSponsor] = useCreateSponsorMutation();
    const [logoFile, setLogoFile] = useState<File | null>(null);
    const [isloading, setIsLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const [updateSponsor] = useUpdateSponsorMutation();
    const [deleteSponsor] = useDeleteSponsorMutation();
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormValues>({
        resolver: yupResolver(editing ? updateSchema : schema)
    });

    const onDrop = useCallback((acceptedFiles: any) => {
        setLogoFile(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    useEffect(() => {
        if (logoFile) {
            setValue('logoFile', logoFile);
        }
    }, [logoFile, setValue]);

    useEffect(() => {
        if (location.state) {
            setEditing(true);
            setValue('title', location?.state?.title);
            setValue('description', location?.state?.description);
            setLogoUrl(location?.state?.logo);
        }
    }, [location.state, setValue]);



    const onSubmit = async (data: any) => {
        setIsLoading(true);
        const formData = new FormData();
        if (editing) {
            if (logoFile) {
                formData.append('logoFile', logoFile);
            }
            formData.append('title', data.title);
            formData.append('description', data.description);
            const id = location?.state?.id;
            formData.append('id', id)
            let body = { formData, id }

            await updateSponsor(body).unwrap().then((res) => {
                setIsLoading(false);
                navigate('/sponsor');
            }).catch((err) => {
                setIsLoading(false);
            });
        } else {
            if (logoFile) {
                formData.append('logoFile', logoFile);
            }
            formData.append('title', data.title);
            formData.append('description', data.description);
            // Call the API with formData
            await createSponsor(formData).unwrap().then((res) => {
                setIsLoading(false);
                navigate('/sponsor');
            }).catch((err) => {
                setIsLoading(false);
            });
        }
    }

    const handleRemoveLogo = () => {
        setLogoFile(null);
    }

    const handleRemoveLogoUrl = () => {
        setLogoUrl(null);
    }

    return (
        <>
            <Helmet>
                <title>Account | RentStream</title>
            </Helmet>
            {isloading && (
                <IsLoading />
            )}
            <div
                className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
                data-nc-id="PageAddListing1"
            >
                <form className="space-y-11" onSubmit={handleSubmit(onSubmit)} >

                    <div className="listingSection__wrap rounded">
                        <h2 className="text-2xl font-semibold">{editing ? 'Edit Sponsor' : 'Add Sponsor'}</h2>
                        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                        <div className="space-y-8">
                            <FormItem
                                label="Sponsor logo"
                            >
                                <div {...getRootProps()} className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md ${errors.logoFile ? "border-red-500" : ""}`}>
                                    {logoUrl !== null ? (
                                        <div className="mt-5 flex justify-center">
                                            <div className="w-24 h-24 relative">
                                                <img
                                                    src={logoUrl}
                                                    alt="Sponsor logo"
                                                    className="object-cover rounded-md w-full h-full"
                                                // {...register('logoFile')}
                                                />
                                                <button
                                                    type="button"
                                                    className="absolute top-0 right-0 p-1 bg-red-500 rounded shadow-md hover:bg-red-600 focus:outline-none"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleRemoveLogoUrl();
                                                    }}
                                                >
                                                    <span className="text-white text-xs font-bold">x</span>
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        logoFile !== null ? (
                                            <div className="mt-5 flex justify-center">
                                                <div className="w-24 h-24 relative">
                                                    <img
                                                        src={URL.createObjectURL(logoFile)}
                                                        alt="Sponsor logo"
                                                        className="object-cover rounded-md w-full h-full"
                                                    // {...register('logoFile')}
                                                    />
                                                    <button
                                                        type="button"
                                                        className="absolute top-0 right-0 p-1 bg-red-500 rounded shadow-md hover:bg-red-600 focus:outline-none"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleRemoveLogo();
                                                        }}
                                                    >
                                                        <span className="text-white text-xs font-bold">x</span>
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="mt-5 flex justify-center">

                                                <div className="space-y-1 text-center">
                                                    <svg
                                                        className="mx-auto h-12 w-12 text-neutral-400"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        viewBox="0 0 48 48"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        ></path>
                                                    </svg>
                                                    <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                                                        <label
                                                            htmlFor="file-upload"
                                                            className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                                                        >
                                                            <span>Upload a file</span>
                                                            <input
                                                                type="file"
                                                                id="logoFile"
                                                                className="sr-only"
                                                                accept=".png,.jpg,.jpeg"
                                                                {...getInputProps()}
                                                                {...register('logoFile')}
                                                                value={undefined}
                                                                onChange={(e) => {
                                                                    if (e.target.files) {
                                                                        setLogoFile(e.target.files[0]);
                                                                    }
                                                                }
                                                                }
                                                            />
                                                        </label>
                                                        <p className="pl-1">or drag and drop</p>
                                                    </div>
                                                    <p className="text-xs text-neutral-500 dark:text-neutral-400">
                                                        PNG, JPG, GIF up to 10MB
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </FormItem>
                            {errors.logoFile && <span className="text-red-500">{errors.logoFile.message}</span>}

                            <FormItem
                                label="Sponsor title"
                            >
                                <Input placeholder="Sponsor title" className={`mt-1.5 ${errors.title ? "border-red-500" : ""}`} {...register('title')} />
                                {errors.title && <span className="text-red-500">{errors.title.message}</span>}
                            </FormItem>
                            <FormItem label="Description">
                                <Textarea rows={14} placeholder="Sponsor's description ..." className={`mt-1.5 ${errors.description ? "border-red-500" : ""}`} {...register('description')} />
                                {errors.description && <span className="text-red-500">{errors.description.message}</span>}
                            </FormItem>
                        </div>
                    </div>

                    {/* --------------------- */}
                    <div className="flex justify-end space-x-5">
                        <ButtonSecondary href="/sponsor">Go back</ButtonSecondary>
                        <ButtonPrimary type="submit">
                            Submit
                        </ButtonPrimary>
                    </div>
                </form>
            </div>

        </>
    );
};

export default AddSponsor;
