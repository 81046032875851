import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";

const PrivacyPage = () => {
    const renderHeader = () => {
        return (
            <header className="container rounded-xl">
                <div className="max-w-screen-md mx-auto space-y-5">
                    <Badge href="##" color="purple" name="Updated 4/24/2023" />
                    <h1
                        className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl pb-6"
                    >
                        Privacy Policy
                    </h1>
                </div>
            </header>
        );
    };

    const renderContent = () => {
        return (
            <div
                id="single-entry-content"
                className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
            >
                <p>
                    This privacy policy ("Policy") describes how RentStream and its related companies ("Company") collect, use and share the personal information of consumer users of this website, www.rentstreamcapital.com (the "Site"). This Policy also applies to any other websites that post this Policy. However, this Policy does not apply to websites that post different statements.
                </p>
                <h3>WHAT WE COLLECT</h3>
                <p>
                    We get information about you in a range of ways.
                </p>
                <ul>
                    <li><strong>Information You Give Us.</strong>{" "}We collect your‎ name, postal address, email address, phone number, fax number, username, password, demographic information (such as your gender and occupation), and other information you give us on our Site.</li>
                    <li><strong>Information We Get From Others.</strong>{" "}We may get information about you from other sources. We may add this to the information we get from this Site.</li>
                    <li><strong>Information We Automatically Collect.</strong>{" "}We automatically log information about you and your computer. For example, when visiting our Site, we log your computer operating system type, browser type, browser language, the website you visited before browsing our Site, pages you viewed, how long you spent on a page, access times, and information about your use of and actions on our Site.</li>
                    <li><strong>Cookies.</strong>{" "}We may log information using "cookies." Cookies are small data files stored on your hard drive by a website. We may use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on your computer until you delete them) to provide a more personal and interactive experience on our Site.   This type of information is collected to make the Site more useful to you and to tailor the experience with us to meet your special interests and needs.</li>
                </ul>
                <h3>USE OF PERSONAL INFORMATION </h3>
                <p>
                    We use your personal information as follows:
                </p>
                <ul>
                    <li>We use your personal information to operate, maintain, and improve our sites, products, and services.</li>
                    <li>We use your personal information to process and deliver contest entries and rewards.</li>
                    <li>We use your personal information to respond to comments and questions and provide customer service.</li>
                    <li>We use your personal information to send information, including confirmations, invoices, technical notices, updates, security alerts, and support and administrative messages.</li>
                    <li>We use your personal information to communicate about promotions, upcoming events, and other news about products and services offered by our selected partners and us.</li>
                    <li>We use your personal information to link or combine user information with other personal information.</li>
                    <li>We use your personal information to protect, investigate, and deter fraudulent, unauthorized, or illegal activity.</li>
                    <li>We use your personal information to provide and deliver products and services customers request.</li>
                </ul>
                <h3>SHARING OF PERSONAL INFORMATION</h3>
                <p>
                    We may share personal information as follows:
                </p>
                <ul>
                    <li>We may share personal information with your consent. For example, let us share personal information with others for their marketing uses. Those uses will be subject to their privacy policies.</li>
                    <li>We may share personal information when we do a business deal, or negotiate a business deal, involving the sale or transfer of all or a part of our business or assets. These deals can include any merger, financing, acquisition, or bankruptcy transaction or proceeding.</li>
                    <li>We may share personal information for legal, protection, and safety purposes.</li>
                    <li>We may share information to comply with laws.</li>
                    <li>We may share information to respond to lawful requests and legal processes.</li>
                    <li>We may share information to protect the rights and property of RentStream, our agents, customers, and others. This includes enforcing our agreements, policies, and terms of use.</li>
                    <li>We may share information in an emergency. This includes protecting the safety of our employees and agents, customers, or anyone.</li>
                    <li>We may share aggregated and/or anonymized data with those who need it to do work for us.</li>
                </ul>
                <h3>INFORMATION CHOICES AND CHANGES </h3>
                <p>
                    Our marketing emails tell you how to "opt-out." However, we may still send you non-marketing emails if you opt-out. Non-marketing emails include emails about your accounts and our business dealings with you.
                </p>
                <p>
                    You may send requests about the personal information to our Contact Information below. In addition, you can change contact choices, opt-out of our sharing with others, and update your personal information.
                </p>
                <p>You can typically remove and reject cookies from our Site with your browser settings. Unfortunately, many browsers are set to accept cookies until you change your settings. Therefore, removing or rejecting our cookies could affect how our Site works for you.</p>
                <h3>CONTACT INFORMATION</h3>
                <p>
                    We welcome your comments or questions about this privacy policy. You may also contact us at our address:
                </p>
                <p>
                    RentStream<br/>
                    1120 NASA Parkway Suite 220C<br/>
                    Houston, TX 77058
                </p>
                <h3>CHANGES TO THIS PRIVACY POLICY</h3>
                <p>
                    We may change this privacy policy. We will change the Last Updated date above if we make any changes.
                </p>
            </div>
        );
    };

    const renderTags = () => {
        return (
            <div className="max-w-screen-md mx-auto flex flex-wrap">
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Garden
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Jewelry
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Tools
                </a>
            </div>
        );
    };

    const renderAuthor = () => {
        return (
            <div className="max-w-screen-md mx-auto ">
                <div className="nc-SingleAuthor flex">
                    <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
                    <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              WRITEN BY
            </span>
                        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                            <a href="/">Fones Mimi</a>
                        </h2>
                        <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
              There’s no stopping the tech giant. Apple now opens its 100th
              store in China.There’s no stopping the tech giant.
              <a className="text-primary-6000 font-medium ml-1" href="/">
                Readmore
              </a>
            </span>
                    </div>
                </div>
            </div>
        );
    };

    const renderCommentForm = () => {
        return (
            <div className="max-w-screen-md mx-auto pt-5">
                <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
                    Responses (14)
                </h3>
                <form className="nc-SingleCommentForm mt-5">
                    <Textarea />
                    <div className="mt-2 space-x-3">
                        <ButtonPrimary>Submit</ButtonPrimary>
                        <ButtonSecondary>Cancel</ButtonSecondary>
                    </div>
                </form>
            </div>
        );
    };

    const renderCommentLists = () => {
        return (
            <div className="max-w-screen-md mx-auto">
                <ul className="nc-SingleCommentLists space-y-5">
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    };

    const renderPostRelated = (post: PostDataType) => {
        return (
            <div
                key={post.id}
                className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
            >
                <Link to={post.href} />
                <NcImage
                    className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
                    src={post.featuredImage}
                />
                <div>
                    <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
                </div>
                <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
                    <Badge name="Categories" />
                    <h2 className="block text-lg font-semibold text-white ">
                        <span className="line-clamp-2">{post.title}</span>
                    </h2>

                    <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {post.author.displayName}
            </span>
                        <span className="mx-1.5 font-medium">·</span>
                        <span className="font-normal truncate">{post.date}</span>
                    </div>
                </div>
                <Link to={post.href} />
            </div>
        );
    };

    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>Privacy Policy | RentStream</title>
            </Helmet>
            {renderHeader()}

            <div className="nc-SingleContent container pb-10">
                {renderContent()}
            </div>
        </div>
    );
};

export default PrivacyPage;
