import Heading from "components/Heading/Heading";
import React from "react";
import NcImage from "shared/NcImage/NcImage";
import {Link} from "react-router-dom";

export interface People {
  id: string;
  name: string;
  job: string;
  href: string;
}

const FOUNDER_DEMO: People[] = [
  {
    id: "1",
    name: `Andre Norman`,
    job: "Co-founder and CEO",
    href:
      "https://www.linkedin.com/in/andrenorman/",
  },
  {
    id: "2",
    name: `Jason Howie`,
    job: "Co-founder and President",
    href:
      "https://www.linkedin.com/in/jasonhowie1/",
  },
    {
        id: "2",
        name: `Michael Stankovsky`,
        job: "Co-founder and COO",
        href:
            "https://www.linkedin.com/in/michael-stankovsky-a2a65842/",
    },
];

const SectionFounder = () => {
  return (
    <div className="nc-SectionFounder relative">
      <Heading
        desc="We are leaders with a strong background in mortgage lending, finance, investing, engineering, and supply chain."
      >
        Team
      </Heading>
      <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8">
        {FOUNDER_DEMO.map((item) => (
          <div key={item.id} className="max-w-sm">
            <h3 className="text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
              {item.name}
            </h3>
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
              {item.job}
            </span>
            <span>
              <a
                  className={"text-3xl text-neutral-6000"}
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                title={"LinkedIn"}
            >
          <i className={"lab la-linkedin-in"}></i>
        </a></span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionFounder;
