import React, { FC, useState } from "react";

export interface FlightCardProps {
  className?: string;
  defaultOpen?: boolean;
  data: {
    id: string;
    question: string;
    answer: string;
  };
}

const FlightCard: FC<FlightCardProps> = ({
  className = "",
  data,
  defaultOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const renderDetailTop = () => {
    return (
      <div>
        <div className="flex flex-col md:flex-row ">
          <div className="flex my-5 md:my-0">
            <div className="space-y-10">
              <div className="flex flex-col space-y-1">
                <span className="text-neutral-500 dark:text-slate-900">
                  {data.answer}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderDetail = () => {
    if (!isOpen) return null;
    return (
      <div className="p-4 md:p-8 border border-neutral-200 dark:border-neutral-300 rounded">
        {renderDetailTop()}
      </div>
    );
  };

  return (
    <div
      className={`nc-FlightCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-100 border
     dark:border-neutral-300 rounded overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
      data-nc-id="FlightCard"
    >
      <div
        className={` sm:pr-20 relative  ${className}`}
        data-nc-id="FlightCard"
      >

        <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-200 rounded-full flex items-center justify-center cursor-pointer ${
            isOpen ? "transform -rotate-180" : ""
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="text-xl las la-angle-down text-slate-900"></i>
        </span>

        <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">

          {/* FOR MOBILE RESPONSIVE */}
          <div className="block lg:hidden space-y-1 text-slate-900">
            <div className="flex font-semibold">
              <div>
                <span>{data.question}</span>
              </div>
            </div>
          </div>

          {/* TIME - NAME */}
          <div className="hidden lg:block  min-w-[150px] flex-[4] text-slate-900">
            <div className="font-medium text-lg">{data.question}</div>
          </div>
        </div>
      </div>

      {/* DETAIL */}
        {renderDetail()}
    </div>
  );
};

export default FlightCard;
