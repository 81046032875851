import { CognitoUser, CognitoUserAttribute, CognitoUserSession, AuthenticationDetails } from 'amazon-cognito-identity-js';
import userPool from 'hooks/userPool';

interface ICognitoService {
  signIn: (email: string, password: string) => Promise<CognitoUserSession>;
  signOut: () => void;
  signUp: (email: string, password: string, name: string) => Promise<void>;
  confirmConfirmationCode: (email: string, confirmationCode: string) => Promise<void>;
  resendConfirmationCode: (email: string) => Promise<void>;
}

class CognitoService implements ICognitoService {
    async signIn(email: string, password: string): Promise<CognitoUserSession> {
    const authenticationData = { Username: email, Password: password };
    const userData = { Username: email, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const session = await new Promise<CognitoUserSession>((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });

    return session;
  }

  signOut(): void {
    const user = userPool.getCurrentUser();
    if (user) {
      user.signOut();
    }
  }

  async signUp(email: string, password: string, name: string): Promise<void> {
    const userAttributes: CognitoUserAttribute[] = [
      new CognitoUserAttribute({ Name: 'email', Value: email }),
      new CognitoUserAttribute({ Name: 'name', Value: name }),
    ];

    return new Promise<void>((resolve, reject) => {
      userPool.signUp(email, password, userAttributes, [], (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      });
    });
  }

  async confirmConfirmationCode(email: string, confirmationCode: string): Promise<void> {
    const userData = { Username: email, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    return new Promise<void>((resolve, reject) => {
      cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      });
    });
  }

  async resendConfirmationCode(email: string): Promise<void> {
    const userData = { Username: email, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    return new Promise<void>((resolve, reject) => {
      cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      });
    });
  }
}

const cognitoService = new CognitoService();
export default cognitoService;
