import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface NotificationPayload {
  message: string;
  status: 'success' | 'error' | 'warning' | 'info';
}

const initialState: NotificationPayload = {
  message: '',
  status: 'info',
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification: (state, action: PayloadAction<NotificationPayload>) => {
      state.message = action.payload.message;
      state.status = action.payload.status;
    },
    clearNotification: (state) => {
      state.message = '';
      state.status = 'info';
    },
  },
});

export const { setNotification, clearNotification } = notificationSlice.actions;
export type NotificationState = ReturnType<typeof notificationSlice.reducer>;
export { notificationSlice };
