import Logo from "shared/Logo/FooterLogo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Getting started",
    menus: [
      { href: "/about", label: "About Us" },
      { href: "/strategy", label: "Strategy" },
      { href: "/contact", label: "Contact Us" },
      // { href: "#", label: "Release Notes" },
      // { href: "#", label: "Upgrade Guide" },
      // { href: "#", label: "Browser Support" },
      // { href: "#", label: "Editor Support" },
    ],
  },
  {
    id: "1",
    title: "Explore",
    menus: [
      { href: "#", label: "Funds" },
      // { href: "#", label: "Prototyping" },
      // { href: "#", label: "Design systems" },
      // { href: "#", label: "Pricing" },
      // { href: "#", label: "Security" },
    ],
  },
  // {
  //   id: "2",
  //   title: "Resources",
  //   menus: [
  //     // { href: "/blog", label: "Blog" },
  //     { href: "/loan-to-value-ratio-calculator", label: "LTV Calculator" },
  //     { href: "/valuation-calculator", label: "Valuation Calculator" },
  //     // { href: "#", label: "Developers" },
  //     // { href: "#", label: "Learn design" },
  //     // { href: "#", label: "Releases" },
  //   ],
  // },
  {
    id: "4",
    title: "Legal",
    menus: [
      // { href: "/terms", label: "Terms and Conditions" },
      { href: "/privacy", label: "Privacy Policy" },
      // { href: "#", label: "Community Resources" },
      // { href: "#", label: "Contributing" },
      // { href: "#", label: "Concurrent Mode" },
    ],
  },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-white dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-400 dark:text-neutral-300 hover:text-teal-600 dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-24 lg:py-28 border-t border-neutral-200 dark:border-neutral-700 bg-slate-900">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo className="w-36" />
          </div>
          {/*<div className="col-span-2 flex items-center md:col-span-3">*/}
          {/*  <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />*/}
          {/*</div>*/}
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
      <div className=" container w-full border-b border-neutral-700 dark:border-neutral-800 pt-12"></div>
      <div className={"container pt-12 text-neutral-400 text-sm"}>Investing involves risk, including loss of principal. Past performance does not guarantee or indicate future results. Any historical returns, expected returns, or probability projections may not reflect actual future performance. While the data we use from third parties is believed to be reliable, we cannot ensure the accuracy or completeness of data provided by investors or other third parties. Neither RentStream Capital LLC nor any of its affiliates provide tax advice and do not represent in any manner that the outcomes described herein will result in any particular tax consequence. Offers to sell, or solicitations of offers to buy, any security can only be made through official offering documents that contain important information about investment objectives, risks, fees and expenses. Prospective investors should consult with a tax or legal adviser before making any investment decision.</div>
    </div>
  );
};

export default Footer;
