import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Reducer } from 'redux';
import { RESET_STATE_ACTION_TYPE } from './actions/resetState';
import authSlice from './authSlice';
import { SYNDICATION_API_REDUCER_KEY, syndicationListApi } from './syndicationListApi';
// this is being handled in createApi for now
// import { unauthenticatedMiddleware } from './unauthenticatedMiddleware';
import { notificationSlice } from './notificationSlice';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import formSlice from './formSlice';
import { rtkQueryErrorLogger } from './helpers';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth', 'syndicationListApi, formSlice'],
  expire: 60 * 60 * 1000
};

const reducers = {
  [notificationSlice.name]: notificationSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [formSlice.name]: formSlice.reducer,
  [SYNDICATION_API_REDUCER_KEY]: syndicationListApi.reducer,
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

export const rootReducer: Reducer<RootState> = (
  state: any,
  action: { type: string }
) => {
  if (action.type === RESET_STATE_ACTION_TYPE) {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const serialized = {
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
};

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: serialized.serializableCheck,
    }).concat([syndicationListApi.middleware, rtkQueryErrorLogger]),
});


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducer>;
export const useTypedDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const persistor = persistStore(store);
