import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import { useDispatch, useSelector } from "react-redux";
import ButtonSecondary from "../../shared/Button/ButtonSecondary";
import authSlice from "redux/authSlice";
import AvatarDropdown from "./AvatarDropdown";
import SwitchDarkMode from "../../shared/SwitchDarkMode/SwitchDarkMode";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const signedIn = useSelector((state: any) => state.auth.signedIn);

  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-3 relative flex justify-between items-center">
        <div className="md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
          <Logo />
          {/*<Navigation />*/}
        </div>

        {/*<div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">*/}
        {/*  /!*<HeroSearchForm2MobileFactory />*!/*/}
        {/*</div>*/}

        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden xl:flex items-center space-x-0.5">
            {/*<SwitchDarkMode />*/}
            {/*<SearchDropdown />*/}
            <Navigation />
            {!signedIn ? (
              <>
            <div className="px-1" />
            <ButtonSecondary href="/login">Log In</ButtonSecondary>
            <div className="px-1" />
            <ButtonPrimary href="/signup">Sign Up</ButtonPrimary>
            </>
            ) : (
              <>
                <AvatarDropdown />
            </>
            )}
          </div>
          <div className="flex xl:hidden items-center">
            {/*<SwitchDarkMode />*/}
            <div className="px-0.5" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
