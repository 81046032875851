import React, { useState } from "react";
import FormItem from "../PageAddListing/FormItem";
import Input from "../../shared/Input/Input";
import IsLoading from "../../components/IsLoading";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";

const LoanToValueCalculator: React.FC = () => {
    const [propertyValue, setPropertyValue] = useState<number>(0);
    const [loanAmount, setLoanAmount] = useState<number>(0);
    const [loanToValueRatio, setLoanToValueRatio] = useState<number>(0);

    const calculateLoanToValueRatio = () => {
        if (propertyValue && loanAmount) {
            setLoanToValueRatio((loanAmount / propertyValue) * 100);
        } else {
            setLoanToValueRatio(0);
        }
    };
    const [isLoading, setIsLoading] = useState<boolean>(false);

    return (
        <div
            className="px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32"
        >
            {isLoading && (
                <IsLoading />
            )}
            <h2 className="text-2xl font-semibold mb-10">Loan to Value (LTV) Calculator</h2>

                <div className="listingSection__wrap rounded">
                    <p>Loan to Value Ratio:</p>
                    <h2 className="text-2xl font-semibold">{loanToValueRatio.toFixed(2)}%</h2>
                    <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="space-y-8">
                        <FormItem label="Property Value">
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500">$</span>
                                </div>
                                <Input id="propertyValue" value={propertyValue} onChange={(e) => setPropertyValue(Number(e.target.value))} className="!pl-8 !pr-10" placeholder="0.00" />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500">USD</span>
                                </div>
                            </div>
                        </FormItem>
                        <FormItem label="Loan Amount">
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500">$</span>
                                </div>
                                <Input className="!pl-8 !pr-10" placeholder="5" id="loanAmount" value={loanAmount} onChange={(e) => setLoanAmount(Number(e.target.value))}/>
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500">USD</span>
                                </div>
                            </div>
                        </FormItem>
                    </div>
                    <div className="flex space-x-5">
                        <ButtonPrimary onClick={calculateLoanToValueRatio}>
                            Calculate LTV Ratio
                        </ButtonPrimary>
                    </div>
                    <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
                    <p className="text-neutral-500 text-sm">LTV ratio of 80% or less is ideal.</p>
                </div>
            <div
                id="single-entry-content"
                className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark pt-10"
            >
            <p><strong>Loan to Value (LTV)</strong> is a financial term used to describe the ratio between the amount of a loan and the appraised value of an asset. In most cases, the asset being referred to is a property, such as a house or a piece of land.</p>

                <p>LTV is an important factor for lenders when considering whether or not to approve a loan application. It is also used to determine the terms of a loan, such as the interest rate and the amount of the down payment required.</p>

                <p>The formula for calculating LTV is simple: divide the amount of the loan by the appraised value of the property, then multiply by 100 to get a percentage. For example, if a borrower wants to purchase a $200,000 house and needs a $160,000 mortgage, the LTV would be calculated as follows:</p>

                <p>LTV = (160,000 / 200,000) x 100 = 80%</p>

                <p>In this case, the borrower is asking for a loan that is 80% of the appraised value of the property. A higher LTV ratio indicates a greater risk for the lender, as the borrower has less equity in the property and may be more likely to default on the loan.</p>

                <p>Most lenders have LTV limits that they are willing to work with, as a way to manage their risk. For example, a lender may only be willing to offer a mortgage with an LTV of 80% or lower, meaning that the borrower would need to come up with a down payment of at least 20% of the appraised value of the property.</p>
            </div>
        </div>


    );
};

export default LoanToValueCalculator;
