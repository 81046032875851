import { CognitoUserPool } from "amazon-cognito-identity-js";

let userPool: CognitoUserPool;

if (process.env.NODE_ENV === "production") {
    userPool = new CognitoUserPool({
        UserPoolId: process.env.REACT_APP_USER_POOL_ID_PROD ?? "",
        ClientId: process.env.REACT_APP_CLIENT_ID_PROD ?? "",
    });
    } else {
    userPool = new CognitoUserPool({
        UserPoolId: process.env.REACT_APP_USER_POOL_ID_DEV ?? "",
        ClientId: process.env.REACT_APP_CLIENT_ID_DEV ?? "",
    });
}

export default userPool;